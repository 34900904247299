import { Divider, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import {
  ResultadoWrapper,
  Survey,
  Frame,
  Section,
  GreenContainer,
  RedContainer,
  ResultLineWrapper,
  Thermometer,
  YellowContainer,
  StyledBigNumber,
  Content,
} from "./styled";
import { trpc } from "@api/client";
import { useTheme } from "styled-components";
import { isNaN } from "formik";

export default function ResultsTab({ data }) {
  const theme = useTheme() as any;

  const results = data?.result?.[0];

  const companyLowAnswersPercentage =
    (results?.company_respondents_low_answers /
      results?.company_respondents_total_count) *
    100;
  const companyMediumAnswersPercentage =
    (results?.company_respondents_medium_answers /
      results?.company_respondents_total_count) *
    100;
  const companyHighAnswersPercentage =
    (results?.company_respondents_high_answers /
      results?.company_respondents_total_count) *
    100;

  const marketLowAnswersPercentage =
    (results?.market_respondents_low_answers /
      results?.market_respondents_total_count) *
    100;
  const marketMediumAnswersPercentage =
    (results?.market_respondents_medium_answers /
      results?.market_respondents_total_count) *
    100;
  const marketHighAnswersPercentage =
    (results?.market_respondents_high_answers /
      results?.market_respondents_total_count) *
    100;

  const engagementAverage = Math.round(results?.engagement_average * 100) / 100;

  return (
    <ResultadoWrapper>
      <Section>
        <Typography
          variant="headline8"
          variantColor={theme?.colors?.neutral[30]}
        >
          Dados da empresa
        </Typography>
        <Content>
          {!isNaN(engagementAverage) && (
            <StyledBigNumber
              orientation={"vertical"}
              title={"Média da empresa"}
              value={engagementAverage}
              valueDetails={"5"}
              icon={"IconBuilding"}
              iconColors={{
                primary: "#6B5B66",
                secondary: "#EBE6E9",
              }}
            />
          )}
          <StyledBigNumber
            orientation={"vertical"}
            title={"Respostas da empresa"}
            value={results?.company_respondents_total_count}
            icon={"IconMessage"}
            iconColors={{
              primary: "#6B5B66",
              secondary: "#EBE6E9",
            }}
          />
          <Survey>
            <Typography
              variant="headline9"
              variantColor={theme?.colors?.neutral[30]}
            >
              Distribuição de respostas da empresa
            </Typography>
            <Frame>
              <ResultLineWrapper>
                <Typography
                  variant="body4"
                  variantColor={theme?.colors?.neutral[30]}
                >
                  Ativamente desengajados (≤2)
                </Typography>
                <Typography
                  variant="body4"
                  variantColor={theme?.colors?.neutral[30]}
                >
                  {results?.company_respondents_low_answers} (
                  {companyLowAnswersPercentage.toFixed(2)}%)
                </Typography>
                <Thermometer>
                  <RedContainer
                    style={{
                      width: `${companyLowAnswersPercentage}%`,
                    }}
                  >
                    <Thermometer width={100} color="#F45A55" />
                  </RedContainer>
                </Thermometer>
              </ResultLineWrapper>
              <Divider orientation="horizontal" />
              <ResultLineWrapper>
                <Typography
                  variant="body4"
                  variantColor={theme?.colors?.neutral[30]}
                >
                  Desengajados (2-4)
                </Typography>
                <Typography
                  variant="body4"
                  variantColor={theme?.colors?.neutral[30]}
                >
                  {results?.company_respondents_medium_answers} (
                  {companyMediumAnswersPercentage.toFixed(2)}%)
                </Typography>
                <Thermometer>
                  <YellowContainer
                    style={{
                      width: `${companyMediumAnswersPercentage}%`,
                    }}
                  >
                    <Thermometer width={100} color="#F8B81C" />
                  </YellowContainer>
                </Thermometer>
              </ResultLineWrapper>
              <Divider orientation="horizontal" />
              <ResultLineWrapper>
                <Typography
                  variant="body4"
                  variantColor={theme?.colors?.neutral[30]}
                >
                  Engajados (≥4)
                </Typography>
                <Typography
                  variant="body4"
                  variantColor={theme?.colors?.neutral[30]}
                >
                  {results?.company_respondents_high_answers} (
                  {companyHighAnswersPercentage.toFixed(2)}%)
                </Typography>
                <Thermometer>
                  <GreenContainer
                    style={{
                      width: `${companyHighAnswersPercentage}%`,
                    }}
                  >
                    <Thermometer width={100} color="#6AA01D" />
                  </GreenContainer>
                </Thermometer>
              </ResultLineWrapper>
            </Frame>
          </Survey>
        </Content>
      </Section>
      {/* <Divider orientation="horizontal" />
      <Section>
        <Typography
          variant="headline8"
          variantColor={theme?.colors?.neutral[30]}
        >
          Dados do mercado
        </Typography>
        <Content>
          <StyledBigNumber
            orientation={"vertical"}
            title={"Média do mercado"}
            value={Math.round(results?.market_average * 100) / 100}
            valueDetails={"5"}
            icon={"IconBuilding"}
            iconColors={{
              primary: "#6B5B66",
              secondary: "#EBE6E9",
            }}
          />
          <StyledBigNumber
            orientation={"vertical"}
            title={"Respostas do mercado"}
            value={results?.market_respondents_total_count}
            icon={"IconMessage"}
            iconColors={{
              primary: "#6B5B66",
              secondary: "#EBE6E9",
            }}
          />
          <Survey>
            <Typography
              variant="headline9"
              variantColor={theme?.colors?.neutral[30]}
            >
              Distribuição de respostas do mercado
            </Typography>
            <Frame>
              <ResultLineWrapper>
                <Typography
                  variant="body4"
                  variantColor={theme?.colors?.neutral[30]}
                >
                  Ativamente desengajados (≤2)
                </Typography>
                <Typography
                  variant="body4"
                  variantColor={theme?.colors?.neutral[30]}
                >
                  {results?.market_respondents_low_answers} (
                  {marketLowAnswersPercentage.toFixed(2)}%)
                </Typography>
                <Thermometer>
                  <RedContainer
                    style={{
                      width: `${marketLowAnswersPercentage}%`,
                    }}
                  >
                    <Thermometer width={100} color="#F45A55" />
                  </RedContainer>
                </Thermometer>
              </ResultLineWrapper>
              <Divider orientation="horizontal" />
              <ResultLineWrapper>
                <Typography
                  variant="body4"
                  variantColor={theme?.colors?.neutral[30]}
                >
                  Desengajados (2-4)
                </Typography>
                <Typography
                  variant="body4"
                  variantColor={theme?.colors?.neutral[30]}
                >
                  {results?.market_respondents_medium_answers} (
                  {marketMediumAnswersPercentage.toFixed(2)}%)
                </Typography>
                <Thermometer>
                  <YellowContainer
                    style={{
                      width: `${marketMediumAnswersPercentage}%`,
                    }}
                  >
                    <Thermometer width={100} color="#F8B81C" />
                  </YellowContainer>
                </Thermometer>
              </ResultLineWrapper>
              <Divider orientation="horizontal" />
              <ResultLineWrapper>
                <Typography
                  variant="body4"
                  variantColor={theme?.colors?.neutral[30]}
                >
                  Engajados (≥4)
                </Typography>
                <Typography
                  variant="body4"
                  variantColor={theme?.colors?.neutral[30]}
                >
                  {results?.market_respondents_high_answers} (
                  {marketHighAnswersPercentage.toFixed(2)}%)
                </Typography>
                <Thermometer>
                  <GreenContainer
                    style={{
                      width: `${marketHighAnswersPercentage}%`,
                    }}
                  >
                    <Thermometer width={100} color="#6AA01D" />
                  </GreenContainer>
                </Thermometer>
              </ResultLineWrapper>
            </Frame>
          </Survey>
        </Content>
      </Section> */}
    </ResultadoWrapper>
  );
}
