import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  dayjs,
  PageContainer,
  Skeleton,
  Tab,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { trpc } from "@api/client";
import { alert } from "../../utils";
import { dimensions } from "./components/ReviewTab/dimensions";
import {
  Header,
  Insights,
  BigDates,
  ReviewTab,
  PeopleTab,
  DetailTab,
  AnswerTab,
  LoadingScreen,
  ManualReminderModal,
  AutoReminderModal,
} from "./components";
import { TabsContainer } from "./styles";
import { QuestionStatisticsType } from "./types";
import ResultsTab from "./components/ResultsTab";
import { EngagementModel } from "@models";
import { EditOwnersModal } from "src/modals";
import { ReviewTabInterface } from "./components/ReviewTab/types";

export default () => {
  const { id = "" } = useParams();
  const { selectedCompany } = useSelectedCompany();

  const [selectedReviewType, setSelectedReviewType] = useState<
    "engagement-dimensions" | "strategies" | "enps"
  >("engagement-dimensions");
  const [selectedSegment, setSelectedSegment] = useState<
    "departments" | "groups" | "roles"
  >("departments");

  const [questionStatistics, setQuestionStatistics] = useState<
    QuestionStatisticsType[]
  >([]);
  const [heatMapDimensions, setHeatMapDimensions] = useState<
    ReviewTabInterface["dimensions"]
  >([]);

  const [selectedStrategySection, setSelectedStrategySection] = useState<
    | "engaja_job_meaning_actions"
    | "engaja_good_management_practices_actions"
    | "engaja_positive_work_environment_actions"
    | "engaja_growth_opportunity_actions"
    | "engaja_trust_in_top_leadership_actions"
    | "engaja_compensation_and_benefits_actions"
  >("engaja_job_meaning_actions");

  // Modais
  const [manualReminderVisible, setManualReminderVisible] = useState(false);
  const [manualReminderErrorVisible, setManualReminderErrorVisible] =
    useState(false);
  const [autoReminderVisible, setAutoReminderVisible] = useState(false);
  const [reminderJobEnabled, setReminderJobEnabled] = useState(false);
  const [ownersEditModal, setOwnersEditModal] = useState<{
    open: boolean;
    engagement?: EngagementModel;
  }>({
    open: false,
    engagement: undefined,
  });

  const { isLoading: reminderloading, mutate: reminder } =
    trpc.reminder.useMutation({
      onSuccess: () => {
        alert("Enviado com sucesso", "success");
        if (manualReminderVisible) setManualReminderVisible(false);
        if (manualReminderErrorVisible) setManualReminderErrorVisible(false);
      },
      onError: (err) => {
        if (err?.message?.includes("notifications.reminder.lastSentDate")) {
          setManualReminderVisible(false);
          setManualReminderErrorVisible(true);
          return;
        }

        alert("Erro ao enviar notificações, tente novamente mais tarde.");
        console.log(err);
        setManualReminderVisible(false);
      },
    });

  const {
    isLoading: updateReminderStatusLoading,
    mutate: updateReminderStatus,
  } = trpc.updateReminderStatus.useMutation({
    onSuccess: (e) => {
      setAutoReminderVisible(false);
      setReminderJobEnabled(e?.value?.reminder);
      alert("Atualizado com sucesso", "success");
    },
    onError: (err) => {
      alert("Erro ao atualizar notificações, tente novamente mais tarde.");
      console.log(err?.message);
      setAutoReminderVisible(false);
    },
  });

  const {
    data: resultsData,
    refetch,
    isLoading,
  } = trpc?.getEngajaMarketResults?.useQuery(
    { engagementId: id },
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: strategiesCounts,
    refetch: getStrategiesCountsByCompanyId,
    isLoading: isLoadingStrategies,
  } = trpc?.getEngajaStrategiesCounts?.useQuery(
    { engagementId: id, sectionReferenceId: selectedStrategySection },
    {
      enabled: selectedReviewType === "strategies",
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const { data: departments, refetch: getDepartmentsByCompanyId } =
    trpc?.getDepartmentsByCompanyId?.useQuery(
      { companyId: selectedCompany?.id },
      {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
      }
    );

  const { data: groups, refetch: getGroupsByCompanyId } =
    trpc?.getGroupsByCompanyId?.useQuery(
      { active: true },
      {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
      }
    );

  const { data: roles, refetch: getRolesByCompanyId } =
    trpc?.getRolesByCompanyId?.useQuery(
      { companyId: selectedCompany?.id },
      {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
      }
    );

  const getCompanyDataBySelectedSegment = () => {
    if (selectedSegment === "departments") return departments || [];
    if (selectedSegment === "groups") return groups || [];
    if (selectedSegment === "roles") return roles || [];
    return [];
  };

  const { refetch: getEngajaHeatmap, isFetching: getEngajaHeatmapLoading } =
    trpc?.getEngajaHeatmap?.useQuery(
      { filter: selectedSegment, engagementId: id },
      {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        onSuccess: (response) => {
          setHeatMapDimensions(
            dimensions?.map(({ label, name }) => {
              return {
                label: label,
                key: name,
                values: [
                  response?.companyData?.find(
                    (d) => d?.section_reference_id === name
                  )?.average_section_value,
                  ...(getCompanyDataBySelectedSegment()?.map(
                    (segment) =>
                      response?.filterData?.find(
                        (d) =>
                          (selectedSegment === "departments"
                            ? d?.department_id
                            : selectedSegment === "groups"
                            ? d?.group_id
                            : d?.role_id) === segment?._id &&
                          d?.section_reference_id === name
                      )?.average_section_value
                  ) || []),
                  response?.filterData?.find(
                    (d) =>
                      (selectedSegment === "departments"
                        ? d?.department_id
                        : selectedSegment === "groups"
                        ? d?.group_id
                        : d?.role_id) === "unallocated" &&
                      d?.section_reference_id === name
                  )?.average_section_value,
                ],
              };
            })
          );
        },
        onError: (e) => {
          console.log("getEngajaHeatmap_error: ", e);
        },
      }
    );

  const {
    data: engagement,
    isLoading: getEngagementByIdLoading,
    refetch: getEngagementById,
  } = trpc?.getEngagementById?.useQuery(
    {
      id,
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (e: any) => {
        if (e?.code === "EMPLOYEE_NOT_ENGAGEMENT_OWNER_ERROR") {
          alert(
            "Você não tem permissão para visualizar os detalhes desta pesquisa."
          );
        }

        setReminderJobEnabled(e?.notifications?.reminder?.enabled || false);
      },
      onError: (e) => {
        alert("Erro ao buscar pesquisa, tente novamente mais tarde.");
        console.log("err: ", e);
      },
    }
  );

  const { data: employee, refetch: getEmployeeByIdRefetch } =
    trpc?.getEmployeeById?.useQuery(
      { id: engagement?.createdBy || "" },
      { retry: false, refetchOnWindowFocus: false, enabled: false }
    );

  const {
    data: insights,
    refetch: getSurveyInsightsRefetch,
    isLoading: getSurveyInsightsRefetchLoading,
  } = trpc?.getSurveyInsights?.useQuery(
    { id },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
      onError: (e) => {
        alert("Erro na busca de insights, tente novamente mais tarde.");
        console.log("err: ", e);
      },
    }
  );

  const generateQuestionStatistics = () => {
    const allQuestions: QuestionStatisticsType[] = engagement?.sections?.reduce(
      (a, c) => {
        return [
          ...a,
          ...(c?.questions?.map((q) => ({ ...q, sectionId: c?.id })) || []),
        ];
      },
      []
    );

    const result = allQuestions?.map((question) => {
      const questionAnswers = insights?.employeeAnswers?.map((employee) => {
        const questionAnswers = employee?.answer
          ?.find((s) => s?.id === question?.sectionId)
          ?.questions?.find((q) => q?.id === question?.id);

        const value = questionAnswers?.value;
        const justifcation = questionAnswers?.justification;

        if (value || Array.isArray(value) || typeof value === "number")
          return value;
        if (justifcation) return justifcation;
        return null;
      });

      if (
        ["scale", "emoji", "heart", "start"]?.includes(
          question?.answerFormat || ""
        )
      ) {
        const min = question?.scaleRange?.min || 0;
        const max = question?.scaleRange?.max || 0;

        const result: {
          name: string;
          label: string;
          value: number;
        }[] = [];

        for (let i = min; i <= max; i++)
          result?.push({
            name: `${i}`,
            label: `${i}`,
            value: questionAnswers?.filter((v) => v === i)?.length || 0,
          });

        // console.log("question: ", question?.title);
        // console.log("questionAnswers: ", questionAnswers);
        // console.log("result: ", result);

        return {
          ...question,
          statistics: result,
        };
      }

      return question;
    });

    setQuestionStatistics(result);
  };

  useEffect(() => {
    if (engagement?._id === id) {
      getSurveyInsightsRefetch();
      getDepartmentsByCompanyId();
      getGroupsByCompanyId();
      getRolesByCompanyId();
      if (engagement?.createdBy) getEmployeeByIdRefetch();
    }
  }, [engagement?._id]);

  useEffect(() => {
    if (
      (departments?.length && selectedSegment === "departments") ||
      (groups?.length && selectedSegment === "groups") ||
      (roles?.length && selectedSegment === "roles")
    )
      getEngajaHeatmap();
  }, [departments, groups, roles, selectedSegment]);

  useEffect(() => {
    if (insights?.employeeAnswers?.length && questionStatistics?.length === 0)
      generateQuestionStatistics();
  }, [insights?.employeeAnswers]);

  if (getEngagementByIdLoading || getSurveyInsightsRefetchLoading)
    return <LoadingScreen />;

  const tabItens = [
    {
      label: "Análise",
      component: (
        <ReviewTab
          strategiesLoading={isLoadingStrategies}
          engagementId={id}
          heatMapLoading={getEngajaHeatmapLoading || false}
          columns={[
            "Empresa",
            ...(getCompanyDataBySelectedSegment()?.map((d) => d?.name) || []),
            "Outros",
          ]}
          dimensions={heatMapDimensions}
          selectedReviewType={selectedReviewType}
          selectedSegment={selectedSegment}
          onSetReviewType={(e) => setSelectedReviewType(e)}
          onSelectSegment={(e) => setSelectedSegment(e)}
          section={strategiesCounts?.questions}
          selectedStrategySection={selectedStrategySection}
          setSelectedStrategySection={setSelectedStrategySection}
          enps={
            ["engaja", "climate", "enps"]?.includes(engagement?.model || "")
              ? {
                  detractors: insights?.enps?.detractors || 0,
                  neutrals: insights?.enps?.neutrals || 0,
                  promoters: insights?.enps?.promoters || 0,
                  score: insights?.enps?.score || 0,
                  statistics: questionStatistics?.find(
                    (q) => q?.scaleRange?.max === 10
                  )?.statistics,
                }
              : undefined
          }
        />
      ),
    },
    {
      label: `Respostas (${insights?.answers || 0})`,
      component: (
        <AnswerTab
          engagement={engagement}
          data={
            insights?.employeeAnswers?.map((e) => ({
              name: e?.name,
              date: "",
              answer: e?.answer,
            })) || []
          }
        />
      ),
    },
    {
      label: `Pessoas (${insights?.selectedEmployees?.length || 0})`,
      component: (
        <>
          <PeopleTab
            employees={
              insights?.selectedEmployees?.map((e) => ({
                id: e?.id,
                name: e?.name,
                visualized: e?.visualized,
                status: e?.answerStatus,
              })) || []
            }
          />
        </>
      ),
    },
    {
      label: "Detalhes",
      component: (
        <DetailTab
          model={engagement?.model}
          createdAt={engagement?.createdAt}
          name={engagement?.name}
          title={engagement?.title}
          description={engagement?.description}
          startDate={engagement?.startDate}
          interactionDeadline={engagement?.interactionDeadline}
          anonymous={engagement?.anonymous}
          notifications={engagement?.notifications}
          createdBy={employee?.name}
          owners={engagement?.owners || []}
        />
      ),
    },
  ];

  if (
    [
      "FuXPPZ0P6KwR3UHydq7Ru", // prod  Demo AnaB
      "eV6Gye9bqvFhJWZ4khrKE", // prod  [Teste AnaB] Demonstrações
      "Q5ZtohPYg2NY0CqNtuDaF", // staging LMS v2
    ].includes(selectedCompany?.id)
  ) {
    tabItens.unshift({
      label: "Resultados",
      component: isLoading ? (
        <Skeleton height={400} />
      ) : (
        <ResultsTab data={resultsData} />
      ),
    });
  }

  return (
    <PageContainer>
      <Header
        id={engagement?._id}
        title={engagement?.title}
        status={engagement?.status}
        options={[
          {
            label: `Editar responsáveis`,
            icon: `IconCheckupList`,
            disabled: false,
            onClick: () =>
              setOwnersEditModal({
                open: true,
                engagement: engagement,
              }),
          },
          {
            label: "Enviar notificação",
            icon: "IconBell",
            onClick: () => {
              if (id) {
                const today = dayjs()?.locale("pt-br");
                const twentyFourHoursAgo = today?.subtract(24, "hour");
                const notificationDate =
                  engagement?.notifications?.reminder?.lastSentDate ||
                  engagement?.sendDate;

                if (notificationDate) {
                  if (twentyFourHoursAgo?.diff(notificationDate) < 0) {
                    setManualReminderErrorVisible(!manualReminderErrorVisible);
                  } else {
                    setManualReminderVisible(!manualReminderVisible);
                  }
                }
              }
            },
          },
          {
            label: `${
              reminderJobEnabled ? "Parar" : "Retomar"
            } notificações automáticas`,
            icon: `IconBell${reminderJobEnabled ? "X" : ""}`,
            disabled: false,
            onClick: () =>
              reminderJobEnabled
                ? setAutoReminderVisible(!autoReminderVisible)
                : updateReminderStatus({ engagementId: id }),
          },
        ]}
      />
      <Insights
        recipients={insights?.selectedEmployees?.length || 0}
        answers={insights?.answers || 0}
        viewRate={insights?.viewRate || 0}
        answerRate={insights?.answerRate || 0}
      />
      <BigDates
        createdAt={engagement?.createdAt}
        sendDate={engagement?.sendDate}
        interactionDeadline={engagement?.interactionDeadline}
        updatedAt={new Date()}
      />
      <TabsContainer>
        <Tab tabItens={tabItens} />
      </TabsContainer>
      <ManualReminderModal
        errorVisible={manualReminderErrorVisible}
        visible={manualReminderVisible}
        loading={reminderloading}
        onClose={() => {
          setManualReminderVisible(false);
          setManualReminderErrorVisible(false);
        }}
        onSubmit={() => reminder({ engagementId: id || "", force: true })}
        notViewed={Math.round(100 - (insights?.viewRate || 0))}
      />
      <AutoReminderModal
        visible={autoReminderVisible}
        loading={updateReminderStatusLoading}
        onClose={() => setAutoReminderVisible(false)}
        onSubmit={() => updateReminderStatus({ engagementId: id })}
        notViewed={Math.round(100 - (insights?.viewRate || 0))}
      />
      <EditOwnersModal
        open={ownersEditModal?.open}
        engagement={ownersEditModal?.engagement}
        onClose={() =>
          setOwnersEditModal({
            open: false,
            engagement: undefined,
          })
        }
        onSuccess={() => getEngagementById()}
      />
    </PageContainer>
  );
};
