import {
  TextField,
  TextArea,
  Icons,
  LinkButton,
  SelectField,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Typography } from "../../../..";
import { Divider } from "../../../../v2";
import {
  StepDescription,
  StepContainer,
  Box as BoxComponent,
} from "../../../common/components";
import { StepInfoInterface } from "./types";
import {
  ErrorAsterisk,
  InfoWrapper,
  SectionContainer,
  SectionSubContainer,
  StyledCheckbox,
  TermsConditionsContainer,
  TextAreaContainer,
} from "./styles";
import { Box } from "@mui/material";
import { useTheme } from "styled-components";
import { trpc } from "@api/client";
import { useUser } from "src/hooks/useUser";

const StepInfo = ({
  disabled,
  hide,
  model,
  name,
  title,
  description,
  termsAndConditions,
  companyData,
  onChange,
}: StepInfoInterface) => {
  const user = useUser();
  const fields = { name, title, description, termsAndConditions };
  const theme = useTheme() as any;
  if (hide) return <></>;

  const { data: pdfURL, isFetching } = trpc.getS3File.useQuery(
    {
      path: "people/engagement/termo_anuencia_engaja_2024.pdf",
    },
    {
      retry: 1,
      refetchOnWindowFocus: false,
    }
  );

  const textAreaProp: any = { disabled: disabled || false };

  return (
    <StepContainer>
      <StepDescription
        model={model}
        title="Informações básicas"
        description="Adicione as informações básicas da sua<br/> pesquisa, como nome de identificação, nome de exibição e descrição."
      />
      <Box display="flex" flexDirection="column" gap="24px" width={"100%"}>
        <BoxComponent>
          <SectionContainer>
            <SectionSubContainer>
              <Typography
                variant="headline7"
                color="var(--color-neutral-30)"
                fontWeight={700}
              >
                Nome e descrição
              </Typography>
              <Divider spacing="quark" />
              <Typography
                variant="body4"
                color="var(--color-neutral-50)"
                fontWeight={400}
              >
                Dê um nome para identificar a pesquisa, um nome público e
                descreva o objetivo.
              </Typography>
            </SectionSubContainer>
            <div style={{ display: "flex" }}>
              <Typography
                variant="body4"
                color="var(--color-neutral-50)"
                fontWeight={400}
              >
                <ErrorAsterisk>*</ErrorAsterisk> campo obrigatório
              </Typography>
            </div>
          </SectionContainer>
          <Divider spacing="s1" />
          <TextField
            disabled={disabled}
            label="Nome de identificação da pesquisa *"
            helperText="O nome ficará visível apenas para você na listagem da tabela."
            value={name || ""}
            fullWidth
            inputProps={{
              maxLength: 50,
            }}
            onChange={(e) => onChange({ ...fields, name: e?.target?.value })}
          />
          <InfoWrapper>
            <Typography variant="caption" color={theme?.colors?.neutral40}>
              {name?.length ?? 0}/{50}
            </Typography>
          </InfoWrapper>
          <Divider spacing="xs2" />
          <TextField
            disabled={disabled}
            label="Nome de exibição pública *"
            helperText="O nome de exibição pública será exibido para os usuários."
            value={title || ""}
            fullWidth
            inputProps={{
              maxLength: 30,
            }}
            onChange={(e) => onChange({ ...fields, title: e?.target?.value })}
          />
          <InfoWrapper>
            <Typography variant="caption" color={theme?.colors?.neutral40}>
              {title?.length ?? 0}/{30}
            </Typography>
          </InfoWrapper>
          <Divider spacing="xs2" />
          <TextAreaContainer>
            <TextArea
              {...textAreaProp}
              value={description || ""}
              customInfo={
                <Typography variant="caption">{`A descrição pública da pesquisa será exibida para os usuários.`}</Typography>
              }
              maxLength={300}
              placeholder="Descrição pública da pesquisa *"
              onChange={(e: any) =>
                onChange({ ...fields, description: e?.target?.value || "" })
              }
            />
          </TextAreaContainer>
        </BoxComponent>
        {model === "engaja" && (
          <BoxComponent>
            <SectionContainer>
              <SectionSubContainer>
                <Typography
                  variant="headline7"
                  color="var(--color-neutral-30)"
                  fontWeight={700}
                >
                  Dados da empresa
                </Typography>
                <Divider spacing="quark" />
                <Typography
                  variant="body4"
                  color="var(--color-neutral-50)"
                  fontWeight={400}
                >
                  Preencha os dados da sua empresa.
                </Typography>
              </SectionSubContainer>
              <div style={{ display: "flex" }}>
                <Typography
                  variant="body4"
                  color="var(--color-neutral-50)"
                  fontWeight={400}
                >
                  <ErrorAsterisk>*</ErrorAsterisk> campo obrigatório
                </Typography>
              </div>
            </SectionContainer>
            <Divider spacing="s1" />
            <SelectField
              disabled={disabled}
              label="Setor econômico da empresa *"
              fullWidth
              options={[
                {
                  label: "Indústria",
                  value: "industry",
                },
                {
                  label: "Comércio varejista",
                  value: "retail",
                },
                {
                  label: "Comércio atacadista",
                  value: "wholesale",
                },
                {
                  label:
                    "Serviços (consultoria, advocacia, telefonia, seguros, etc.)",
                  value: "services",
                },
                {
                  label: "Serviços financeiros",
                  value: "financial",
                },
                {
                  label: "Educação",
                  value: "education",
                },
                {
                  label: "Saúde",
                  value: "healthcare",
                },
                {
                  label: "Cultura ou turismo",
                  value: "culture",
                },
                {
                  label: "Construção civil",
                  value: "construction",
                },
                {
                  label: "Tecnologia",
                  value: "technology",
                },
                {
                  label: "Transportes",
                  value: "transportation",
                },
                {
                  label: "Outros",
                  value: "other",
                },
              ]}
              onSelectChange={(_: any, option) => {
                const value = option?.value;

                onChange({
                  ...fields,
                  companyData: {
                    ...(companyData || {}),
                    economicSector: value,
                  },
                });
              }}
              value={companyData?.economicSector}
            />
            <Divider spacing="xs2" />
            <SelectField
              disabled={disabled}
              label="Número de colaboradores da empresa *"
              fullWidth
              options={[
                {
                  label: "Até 50 colaboradores",
                  value: "1-50",
                },
                {
                  label: "De 51 a 200 colaboradores",
                  value: "51-200",
                },
                {
                  label: "De 201 a 500 colaboradores",
                  value: "201-500",
                },
                {
                  label: "De 501 a 1000 colaboradores",
                  value: "501-1000",
                },
                {
                  label: "De 1001 a 5000 colaboradores",
                  value: "1001-5000",
                },
                {
                  label: "Acima de 5001",
                  value: "5000+",
                },
                {
                  label: "Não sei/Não tenho certeza",
                  value: "unknown",
                },
              ]}
              onSelectChange={(_: any, option) => {
                const value = option?.value;

                onChange({
                  ...fields,
                  companyData: {
                    ...(companyData || {}),
                    employees: value,
                  },
                });
              }}
              value={companyData?.employees}
            />
            <Divider spacing="xs2" />
            <SelectField
              disabled={disabled}
              label="Porte da empresa (Faturamento anual) *"
              fullWidth
              options={[
                {
                  label: "MEI - Até R$ 81 mil",
                  value: "mei",
                },
                {
                  label: "Microempresa - Até R$ 360 mil",
                  value: "microenterprise",
                },
                {
                  label: "Pequeno Porte - Até R$ 4,8 milhões",
                  value: "small",
                },
                {
                  label: "Médio Porte - Até R$ 300 milhões",
                  value: "medium",
                },
                {
                  label: "Grande Porte - Acima de R$ 300 milhões",
                  value: "large",
                },
                {
                  label: "Não sei/Não tenho certeza",
                  value: "unknown",
                },
              ]}
              onSelectChange={(_: any, option) => {
                const value = option?.value;

                onChange({
                  ...fields,
                  companyData: {
                    ...(companyData || {}),
                    size: value,
                  },
                });
              }}
              value={companyData?.size}
            />
          </BoxComponent>
        )}
        {model === "engaja" && (
          <TermsConditionsContainer>
            <Typography
              variant="body4"
              variantColor={theme?.colors?.neutral40}
              weight={700}
            >
              A pesquisa apresentará dados de análise personalizados entre as
              empresas participantes. O anonimato dos dados é garantido.
            </Typography>
            <Box
              display="flex"
              flexDirection="row"
              gap="12px"
              alignItems="center"
            >
              <StyledCheckbox
                checked={!!fields?.termsAndConditions}
                onChange={(_, checked) =>
                  onChange({
                    ...fields,
                    termsAndConditions: checked
                      ? {
                          ...termsAndConditions,
                          date: new Date().toISOString(),
                          acceptedBy: (user?._id || user?.id) ?? "",
                          type: "engaja",
                        }
                      : null,
                  })
                }
              />
              <Typography
                variant="headline9"
                variantColor={theme?.colors?.neutral50}
                weight={600}
                style={{ display: "flex", gap: "6px" }}
              >
                Li e concordo com os
                <LinkButton
                  variant="default"
                  onClick={() => window.open(pdfURL, "_blank")}
                  disabled={isFetching}
                >
                  {" "}
                  termos e condições{" "}
                </LinkButton>{" "}
                da pesquisa.
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              gap="4px"
              alignItems="center"
            >
              <Icons
                name="IconInfoCircle"
                size={16}
                color={theme?.colors?.error50}
              />
              <Typography
                variant="caption"
                variantColor={theme?.colors?.neutral50}
                weight={600}
              >
                Você deve concordar com os termos e condições para continuar.
              </Typography>
            </Box>
          </TermsConditionsContainer>
        )}
      </Box>
    </StepContainer>
  );
};

export { StepInfo };
