import { Tab } from "@flash-tecnologia/hros-web-ui-v2";
import { ModelContent } from "../FloatContent";
import EngajaModelQuestions from "@components/EngajaModelQuestions";

export default function EngajaModelDetails({
  innerCardProps,
  modelDescription,
}) {
  return (
    <Tab
      tabItens={[
        {
          label: `Detalhes`,
          component: (
            <ModelContent
              innerCardProps={innerCardProps}
              modelDescription={modelDescription}
            />
          ),
        },
        {
          label: `Perguntas`,
          component: <EngajaModelQuestions />,
        },
      ]}
    />
  );
}
