import {
  Icons,
  Skeleton,
  Typography,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { trpc } from "../../../api/client";
import {
  StyledTitle,
  alert,
  dispatchToast,
  getPeriod,
  segment,
} from "../../../utils";
import { StepEmployee } from "../common/steps";

import { StepConfiguration, StepDispatch, StepInfo, StepReview } from "./steps";
import { Container } from "./styles";
import { CommunicationFormInterface, CommunicationInterface } from "./types";

import { PageTemplate } from "@components/PageTemplate";
import { useFormik } from "formik";

import { Box, Grid } from "@mui/material";
import { WarningEmployeesModal } from "./Modals/WarningEmployeesModal";
import { validationSchema } from "./validationSchema";
import { Drawer } from "@components/index";
import { Preview } from "./Preview";
import { NewModal } from "@components/NewModal";
import { useTheme } from "styled-components";

const CommunicationForm = ({ id }: CommunicationFormInterface) => {
  const theme = useTheme() as any;
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [imageData, setImageData] = useState<any>(false);
  const [progress, setProgress] = useState(false);

  const [showCorporateEmailModal, setShowCorporateEmailModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [communicationStatus, setCommunicationStatus] = useState<
    "draft" | "send" | "scheduled"
  >();

  const [step, setStep] = useState(0);
  const [communication, setCommunication] = useState<
    CommunicationInterface | undefined
  >();

  const [employees, setEmployees] =
    useState<{ _id: string; name: string; corporateEmail?: string }[]>();

  useEffect(() => {
    formik.setValues({
      _id: communication?._id || undefined,
      owners: communication?.owners || [{ name: "*", id: "*" }],
      name: communication?.name || "",
      title: communication?.title || "",
      message: communication?.message || "",
      status: communication?.status || "draft",
      like: communication?.like || false,
      type: communication?.type || "communication",
      link: communication?.link || { label: "Acesse agora", url: "" },
      showSectionLink: !!communication?.link?.url || false,
      pdfFile: communication?.pdfFile || "",
      showPdfLink: !!communication?.pdfFile?.path || false,
      imageUrl: communication?.imageUrl || "",
      notifications: communication?.notifications || {
        push: false,
        email: false,
        reminder: {
          enabled: true,
          minRate: 60,
        },
      },
      employees: communication?.employees || [],
      startDate: communication?.startDate || { date: "", period: "" },
      recurrenceMode: communication?.recurrence?.frequency
        ? true
        : communication?.startDate?.date && communication?.startDate?.period
        ? false
        : undefined,
      recurrence: {
        recurrenceMode: communication?.recurrence?.frequency
          ? true
          : communication?.startDate?.date && communication?.startDate?.period
          ? false
          : undefined,
        frequency: communication?.recurrence?.frequency || "",
        repeat: communication?.recurrence?.repeat || 1,
        daysOfTheWeek: communication?.recurrence?.daysOfTheWeek || [],
        occurrenceLimit:
          communication?.recurrence?.occurrenceLimit || undefined,
        endType:
          communication?.recurrence?.occurrenceLimit == -1
            ? "never"
            : communication?.recurrence?.occurrenceLimit &&
              communication?.recurrence?.occurrenceLimit > 0
            ? "occurrence"
            : communication?.recurrence?.endDate
            ? "date"
            : undefined,
        endDate: communication?.recurrence?.endDate || "",
        status: communication?.recurrence?.status,
        employees: communication?.recurrence?.employees || [],
      },
      hasDraft: false,
    });
  }, [communication]);

  useEffect(() => {
    id ? getEngagementById() : getEmployeesByCompanyId();
  }, []);

  const routesBread = [
    {
      label: "Comunicação",
      route: "/engagement/communication",
    },
    {
      label: "Criar comunicação",
      route: "",
    },
  ];

  const {
    isFetching: getEmployeesByCompanyIdLoading,
    refetch: getEmployeesByCompanyId,
  } = trpc?.getInternalEmployeesByCompanyId?.useQuery(
    {
      status: "ACTIVE",
    },
    {
      retry: false,
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data?.employees?.length)
          setEmployees([
            ...data?.employees?.map(({ _id, name, corporateEmail }) => ({
              _id,
              name,
              corporateEmail,
            })),
          ]);
      },
      onError: (e: any) => {
        alert("Erro ao buscar destinatários, tente novamente mais tarde.");
        console.log("err: ", e);
      },
    }
  );

  const { isFetching: getEngagementByIdLoading, refetch: getEngagementById } =
    trpc?.getEngagementById?.useQuery(
      {
        id: id || "",
      },
      {
        retry: false,
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
          setCommunication({
            ...data,
            type: "communication",
          });

          if (
            data?._id &&
            data?.recurrence?.status === "progress" &&
            progress === false
          )
            setProgress(true);

          getEmployeesByCompanyId();
        },
        onError: (e: any) => {
          alert("Erro ao buscar comunicação, tente novamente mais tarde.");

          if (e?.data?.error === "ENGAGEMENT_NOT_EXISTS_ERROR")
            navigate(`/engagement/communication`);

          console.log("err: ", e);
        },
      }
    );

  const { isLoading: upsertEngagementLoading, mutate: upsertEngagement } =
    trpc?.upsertEngagement?.useMutation({
      onError: (e: any) => {
        let msgError = "Ocorreu um erro, tente novamente mais tarde.";
        const error = e?.data?.error;

        if (error === "INVALID_START_DATE_ERROR")
          msgError = "Erro: Selecione uma data de envio válida.";
        if (error === "INVALID_PERIOD")
          msgError = "Erro: Selecione um período válido.";

        alert(msgError);
        console.log("err: ", e);
      },
    });

  const {
    isLoading: updateRecurrenceEmployeesLoading,
    mutate: updateRecurrenceEmployees,
  } = trpc?.updateRecurrenceEmployees?.useMutation({
    onSuccess: () => {
      alert("Recorrência atualizada com sucesso", "success");
      navigate(`/engagement/communication`);
    },
    onError: (e: any) => {
      alert("Erro ao atualizar recorrência, tente novamente mais tarde.");
      console.log("error: ", e);
    },
  });

  const createOrUpdateCommunication = (
    hasDraft: boolean,
    navigateToEmployees?: boolean
  ) => {
    const {
      _id,
      startDate,
      name,
      type,
      recurrenceMode,
      employees,
      title,
      groups,
      message,
      imageUrl,
      pdfFile,
      link,
      like,
      owners,
    } = formik?.values;

    const communicationOwners = owners ?? [{ id: "*", name: "*" }];

    const status: "draft" | "send" | "scheduled" = hasDraft
      ? "draft"
      : formik?.values?.status === "send" ||
        (getPeriod(dayjs()) === startDate?.period &&
          dayjs()?.format("YYYY-MM-DD") === startDate?.date)
      ? "send"
      : "scheduled";

    const recurrenceStatus: "scheduled" | "progress" =
      status === "send" ? "progress" : "scheduled";

    if (type && name && status) {
      const recurrence = recurrenceMode
        ? {
            daysOfTheWeek:
              formik?.values?.recurrence?.daysOfTheWeek || undefined,
            endDate: formik?.values?.recurrence?.endDate || undefined,
            frequency: formik?.values?.recurrence?.frequency || undefined,
            occurrenceLimit:
              formik?.values?.recurrence?.occurrenceLimit || undefined,
            repeat: formik?.values?.recurrence?.repeat || undefined,
            status: recurrenceStatus,
            employees: employees || [],
          }
        : undefined;

      if (progress) {
        updateRecurrenceEmployees({
          id: id || "",
          employees: employees || [],
          endDate: recurrence?.endDate,
          occurrenceLimit: recurrence?.occurrenceLimit,
        });
        return;
      }

      const notifications = {
        email: formik?.values?.notifications?.email || false,
        push: formik?.values?.notifications?.push || false,
        reminder:
          formik?.values?.notifications?.reminder?.minRate >= 50
            ? formik?.values?.notifications?.reminder
            : {
                enabled: false,
                minRate: 0,
              },
      };

      const corporateEmails = (
        employees?.map((employeeId) => ({
          _id: employeeId,
          email:
            employees?.find((e) => e?._id === employeeId)?.corporateEmail || "",
        })) || []
      )?.filter((e) => e?._id && e?.email?.length);

      if (status !== "draft") {
        const currentDate = dayjs(dayjs()?.format("YYYY-MM-DD"));

        if (currentDate.diff(startDate?.date, "day") > 0) {
          if (!recurrence?.status) {
            alert("Erro: Selecione uma data de envio válida.");
            return;
          }
        }
      }

      let params = {
        _id: _id || undefined,
        name: name,
        message: message,
        title: title || undefined,
        status,
        type: "communication",
        like: like,
        groups: groups || undefined,
        notifications,
        corporateEmails,
        owners: communicationOwners,
      } as any;

      if (imageUrl?.path) params = { ...params, imageUrl };
      if (pdfFile?.path) params = { ...params, pdfFile };
      if (employees?.length) params = { ...params, employees };
      if (link?.url)
        params = { ...params, link: { label: "Acesse agora", url: link?.url } };

      if (startDate?.date && startDate?.period)
        params = { ...params, startDate: startDate };

      if (recurrence) params = { ...params, recurrence: recurrence };

      upsertEngagement(
        {
          ...params,
        },
        {
          onSuccess: () => {
            if (navigateToEmployees) {
              alert("Rascunho salvo com sucesso", "success");
              navigate("/employees");
            } else {
              setCommunicationStatus(status);

              if (showCorporateEmailModal) {
                setShowCorporateEmailModal(false);
                navigate(`/engagement/communication`);
                return;
              }

              setShowSuccessModal(true);
            }
          },
        }
      );
    }
  };

  const formik = useFormik<any>({
    initialValues: {
      responsibilityMode: "everyone",
    },
    validationSchema: validationSchema[step],
    validate: (values) => {
      const errors: any = {};

      return errors;
    },
    onSubmit: async (values) => {
      const { hasDraft = false, notifications } = values;
      if (hasDraft) {
        createOrUpdateCommunication(hasDraft);
        return;
      }

      if (step <= 3) {
        setStep(step + 1);
        formik.setTouched({});
      }

      if (step == 4) {
        if (notifications?.email && employeesWithoutCorporateEmailsCount) {
          setShowCorporateEmailModal(!showCorporateEmailModal);
        } else {
          createOrUpdateCommunication(false);
        }
      }
    },
  });

  const employeesWithoutCorporateEmailsCount =
    formik?.values?.employees?.reduce(
      (a, c) =>
        a + (employees?.find((e) => e?._id === c)?.corporateEmail ? 0 : 1),
      0
    ) || 0;

  const validatingFunction = async () => {
    const isValidToSubmit = await formik?.validateForm();
    const arrayOfErrors = Object.keys(isValidToSubmit);

    if (arrayOfErrors?.length) {
      if (arrayOfErrors?.includes("notifications")) {
        dispatchToast({
          content: "Favor selecionar ao menos uma opção de notificação",
          type: "error",
        });
      } else if (arrayOfErrors?.includes("employees")) {
        dispatchToast({
          content: "Favor adicionar ao menos um destinatário",
          type: "error",
        });
      } else if (arrayOfErrors?.includes("owners")) {
        dispatchToast({
          content: formik?.errors?.owners,
          type: "error",
        });
      } else {
        dispatchToast({
          content: "Alguns campos estão incorretos. Favor verificar",
          type: "error",
        });
      }
    }
  };

  const handleTrackName = (action: string) => {
    const steps = [
      "communication_infos",
      "send_infos",
      "configuration",
      "recipients",
      "review",
    ];

    return `people_strategic_hr_engagement_company_communication_${steps[step]}_${action}`;
  };

  useEffect(() => {
    if (
      formik?.values?.showSectionLink === false &&
      formik?.values?.link?.url?.length
    ) {
      formik.handleChange({
        target: {
          id: "link",
          value: { label: "Acesse agora", url: "" },
        },
      });
    }
  }, [formik?.values]);

  const isDraft = communicationStatus === "draft";

  return (
    <>
      <Drawer
        content={
          <Preview
            title={formik?.values?.title || ""}
            message={formik?.values?.message || ""}
            image={imageData || formik?.values?.imageUrl?.signedUrl}
            like={formik?.values?.like || false}
            sendDate={formik?.values?.startDate?.date || ""}
            pdf={formik?.values?.pdfFile?.path}
            link={{
              label: "Acesse agora",
              url: formik?.values?.link?.url || "",
            }}
          />
        }
        title="Comunicação"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(!isDrawerOpen)}
      />
      <PageTemplate
        routes={routesBread}
        stepper={{
          steps: [
            "Informações básicas",
            "Envio",
            "Configurações",
            "Destinatários",
            "Revisão",
          ],
          activeStep: step,
        }}
        footer={{
          cancelProps: {
            title: "Sair sem salvar",
            callback: () => {
              segment({ track: handleTrackName("exit_clicked") });
              navigate(`/engagement/communication`);
            },
          },
          draftProps: {
            title: "Sair e salvar rascunho",
            hasToShow: formik?.values?.status == "draft",
            callback: async () => {
              segment({ track: handleTrackName("savedraft_clicked") });
              formik.setFieldValue("hasDraft", true);

              await validatingFunction();
              formik.handleSubmit();
            },
          },
          goBackProps: {
            disabled: step == 0,
            title: (
              <>
                <Icons name="IconArrowLeft" fill="transparent" />
                Voltar
              </>
            ),
            callback: () => {
              segment({ track: handleTrackName("return_clicked") });
              formik.setTouched({});
              setStep(step - 1);
            },
          },
          confirmProps: {
            loading:
              upsertEngagementLoading || updateRecurrenceEmployeesLoading,
            disabled:
              !formik?.values?.owners || formik?.values?.owners?.length === 0,
            title:
              step == 4 ? (
                <> Adicionar comunicação</>
              ) : (
                <>
                  Continuar
                  <Icons name="IconArrowRight" fill="transparent" />
                </>
              ),
            callback: async () => {
              segment({ track: handleTrackName("next_clicked") });
              await validatingFunction();
              formik.handleSubmit();
            },
          },
        }}
      >
        {id && getEngagementByIdLoading ? (
          <Grid container marginTop={"32px"} marginBottom={"32px"}>
            <Grid item sm={12} md={5} lg={4}>
              <div style={{ marginRight: "10px" }}>
                <Skeleton
                  width="100%"
                  height="100vh"
                  animation="pulse"
                  variant="rectangular"
                />
              </div>
            </Grid>
            <Grid item sm={12} md={7} lg={8}>
              <div>
                <Skeleton
                  width="100%"
                  height="100vh"
                  animation="pulse"
                  variant="rectangular"
                />
              </div>
            </Grid>
          </Grid>
        ) : (
          <form onSubmit={formik?.handleSubmit}>
            <Container>
              <StyledTitle variant="headline6" style={{ margin: "32px 0px" }}>
                Criar comunicação
              </StyledTitle>

              <StepInfo
                formik={formik}
                hide={step !== 0}
                disabled={formik?.values?.status === "send"}
                onFileChange={(e) => setImageData(e)}
              />

              <StepDispatch
                disabled={formik?.values?.status === "send"}
                hide={step !== 1}
                formik={formik}
              />

              <StepConfiguration
                formik={formik}
                hide={step !== 2}
                disabled={formik?.values?.status === "send"}
              />
              <StepEmployee
                type="communication"
                notifications={formik?.values?.notifications}
                loading={
                  getEmployeesByCompanyIdLoading ||
                  getEmployeesByCompanyIdLoading
                }
                companyEmployees={employees}
                selectedEmployeeIds={
                  progress
                    ? formik?.values?.recurrence?.employees ||
                      formik?.values?.employees
                    : formik?.values?.employees
                }
                onChange={(e) => {
                  formik.handleChange({
                    target: {
                      id: "employees",
                      value: e,
                    },
                  });
                }}
                showUserWithoutCorporateEmail={
                  !formik?.values?.notifications?.email
                }
                disabled={formik?.values?.status === "send"}
                hide={step !== 3}
                onClick={() => createOrUpdateCommunication(true, true)}
              />

              <StepReview
                disabled={formik?.values?.status === "send"}
                hide={step !== 4}
                setStep={setStep}
                employees={employees}
                formik={formik}
                onPreviewClick={() => setIsDrawerOpen(!isDrawerOpen)}
              />
            </Container>
          </form>
        )}
      </PageTemplate>
      <NewModal
        id={"modal_create_survey"}
        title={
          isDraft
            ? "Seu rascunho criado com sucesso"
            : `Sua Comunicação foi ${
                communicationStatus === "send" ? "enviada" : "agendada"
              } com sucesso. 😄`
        }
        description={
          isDraft ? (
            <Box
              display="flex"
              flexDirection={"column"}
              alignItems={"center"}
              gap="24px"
              textAlign={"justify"}
              width={500}
            >
              <Typography
                variant="body4"
                variantColor={theme?.colors?.neutral[40]}
              >
                Ela estará{" "}
                <strong>disponível na tabela de comunicações, </strong>
                acesse através da opção de editar nas ações.
              </Typography>
            </Box>
          ) : (
            ""
          )
        }
        open={showSuccessModal}
        onClose={() => {
          navigate(`/engagement/communication`);
        }}
        footer={{
          submitButton: {
            label: "Voltar para Comunicações",
            variant: "primary",
            onClick: () => navigate(`/engagement/communication`),
          },
        }}
        status={{
          icon: "IconCircleCheck",
          color: "green",
          message: "Tudo certo!",
        }}
      />

      <WarningEmployeesModal
        loading={upsertEngagementLoading || updateRecurrenceEmployeesLoading}
        employeesWithoutCorporateEmailCount={
          employeesWithoutCorporateEmailsCount
        }
        showCorporateEmailModal={showCorporateEmailModal}
        setShowCorporateEmailModal={setShowCorporateEmailModal}
        onSubmit={() => {
          createOrUpdateCommunication(false);
        }}
      />
    </>
  );
};

export { CommunicationForm };
