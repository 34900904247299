import { useCallback, useState } from "react";
import { Typography } from "../Typography";
import { ColorBlock } from "./components";
import { HeatMapInterface, DrawerContentType } from "./types";
import {
  Container,
  TableContainer,
  TD,
  TH,
  Table,
  Border,
  RequiredMessageContainer,
  Trigger,
} from "./styles";
import { Divider } from "../v2";
import { Asterisk, Drawer } from "..";
import { Icons } from "@flash-tecnologia/hros-web-ui-v2";

const HeatMap = ({ columns, rows, segment, drawerProps }: HeatMapInterface) => {
  const [isOpen, setIsOpen] = useState<
    [boolean, DrawerContentType | undefined]
  >([false, undefined]);

  const segmentLabel =
    segment === "departments"
      ? "Departamentos"
      : segment === "groups"
      ? "Grupos"
      : "Cargos";
  const getColorByTemperature = useCallback((t: number) => {
    if (t <= 2) return "var(--color-neutral-100)";
    if (t > 2 && t <= 3) return "var(--color-secondary-99)";
    if (t > 3 && t <= 4) return "var(--color-secondary-95)";
    if (t > 4 && t < 5) return "var(--color-secondary-90)";
    return "var(--color-secondary-80)";
  }, []);

  const renderValue = useCallback((num: number) => {
    if (num < 0) return "Anônimo";

    const value = `${num}`;

    if (!value?.includes(".")) return value;

    const roundedValue = parseFloat(value).toFixed(2);

    return roundedValue;
  }, []);

  return (
    <>
      <Container>
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <TH>
                  <Border
                    style={{
                      borderTop: "none",
                      width: "calc(100% - 0.5px)",
                    }}
                  >
                    <Typography
                      variant="body3"
                      fontWeight={700}
                      color="var(--color-neutral-20)"
                    >
                      {`Dimensões x ${segmentLabel}`}
                    </Typography>
                  </Border>
                </TH>
                {columns?.map((col, i) => (
                  <TH key={i} scope="col">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        variant="body3"
                        fontWeight={700}
                        color="var(--color-neutral-30)"
                      >
                        {col}
                      </Typography>
                      {rows?.some(({ values }) =>
                        values
                          ?.filter((_, valueIndex) => valueIndex === i)
                          ?.some((v) => v === -1)
                      ) ? (
                        <Asterisk />
                      ) : (
                        ""
                      )}
                    </div>
                  </TH>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows?.map(({ label, values }, rowIndex) => (
                <tr key={`th-${rowIndex}`}>
                  <TD>
                    <Border>
                      <Typography
                        variant="body3"
                        fontWeight={600}
                        color="var(--color-neutral-30)"
                      >
                        {label || ""}
                      </Typography>
                    </Border>
                  </TD>
                  {Array(columns?.length)
                    ?.fill(0)
                    ?.map((intialValue, columnIndex) => {
                      const value = values[columnIndex] || intialValue;
                      const isDrawerTrigger =
                        drawerProps?.columnsIndexes.includes(columnIndex);

                      return (
                        <TD
                          key={`th-${rowIndex}-td-${columnIndex}`}
                          color={
                            value === -1
                              ? "var(--color-neutral-90)"
                              : getColorByTemperature(value)
                          }
                          style={
                            rowIndex === rows?.length - 1 &&
                            columnIndex === values?.length - 1
                              ? {
                                  borderBottomRightRadius: "12px",
                                }
                              : {}
                          }
                        >
                          {isDrawerTrigger ? (
                            <Trigger
                              onClick={() =>
                                setIsOpen((prev) => [
                                  !prev[0],
                                  { label: label, index: rowIndex },
                                ])
                              }
                            >
                              <Typography
                                variant="body3"
                                fontWeight={700}
                                color={
                                  value === -1
                                    ? "var(--color-neutral-30)"
                                    : "var(--color-neutral-10)"
                                }
                              >
                                {renderValue(value)}
                              </Typography>{" "}
                              <Icons name="IconChevronRight" />
                            </Trigger>
                          ) : (
                            <Typography
                              variant="body3"
                              fontWeight={700}
                              color={
                                value === -1
                                  ? "var(--color-neutral-30)"
                                  : "var(--color-neutral-10)"
                              }
                            >
                              {renderValue(value)}
                            </Typography>
                          )}
                        </TD>
                      );
                    })}
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
        <Divider spacing="xxs3" />
        <RequiredMessageContainer>
          <Asterisk />
          <Typography
            variant="body4"
            fontWeight={400}
            color={"var(--color-neutral-30)"}
          >
            Buscando respeitar a identidade dos respondentes, os{" "}
            {segmentLabel?.toLowerCase()} só exibem resultados quando há 3 ou
            mais respostas.
          </Typography>
        </RequiredMessageContainer>
        <Divider spacing="xs2" />
        <ColorBlock
          data={[
            {
              label: "1 - 2",
              color: "var(--color-neutral-100)",
            },
            {
              label: "> 2",
              color: "var(--color-secondary-99)",
            },
            {
              label: "> 3",
              color: "var(--color-secondary-95)",
            },
            {
              label: "> 4",
              color: "var(--color-secondary-90)",
            },
            {
              label: "= 5",
              color: "var(--color-secondary-80)",
            },
          ]}
        />
      </Container>
      {drawerProps && (
        <Drawer
          open={isOpen[0]}
          onClose={() => setIsOpen((prev) => [!prev[0], undefined])}
          content={drawerProps?.content(isOpen[1]).drawerContent}
          title={drawerProps?.content(isOpen[1]).title}
        />
      )}
    </>
  );
};

export { HeatMap };
