import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

const RequiredMessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const TableContainer = styled.div`
  width: 100%;
  border: 1px solid var(--color-neutral-80);
  border-radius: 12px;
  overflow-x: auto;

  tr > th:first-child,
  tr > td:first-child {
    position: sticky;
    left: 0;
    background-color: var(--color-neutral-95);
    border: none !important;
  }

  tr > th,
  tr > td {
    width: auto !important;
  }

  td {
    border: 1px solid white;
  }
`;

const Table = styled.table`
  width: 100%;
`;

const TH = styled.th`
  width: 100%;
  min-width: 170px;
  height: 70px;
  text-align: center;
  background-color: var(--color-neutral-95);
  border-bottom: 1px solid var(--color-neutral-90);
  border-right: 1px solid var(--color-neutral-90);
`;

const TD = styled.td<{ color?: string }>`
  width: 100%;
  min-width: 170px;
  height: 70px;
  text-align: center;
  background-color: ${({ color }) => color || "var(--color-neutral-100)"};
`;

const Border = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-right: 1px solid var(--color-neutral-90);
  border-top: 1px solid var(--color-neutral-90);
`;

const Trigger = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin: auto;
`;

export {
  Container,
  RequiredMessageContainer,
  TableContainer,
  Table,
  TH,
  TD,
  Border,
  Trigger,
};
