import {
  BoxAdornment,
  DatePicker,
  NotificationCard,
  Radio,
  SelectField,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Dayjs } from "@flash-tecnologia/hros-web-ui-v2/dist/utils";
import { segment } from "@utils";
import { useEffect, useState } from "react";
import {
  CounterField,
  DaysPicker,
  LineSeparator,
  Typography,
} from "../../../..";
import { DaysPickerInterface } from "../../../../DaysPicker/types";
import { Divider } from "../../../../v2";
import { Box, StepContainer, StepDescription } from "../../components";
import { Row, StyleContainer } from "./styles";
import { StepDispatchInterface } from "./types";

const StepDispatch = ({
  isCommunication,
  disabled,
  hide,
  model,
  startDate,
  recurrence,
  interactionDeadline,
  onChange,
}: StepDispatchInterface) => {
  const engagementSegmentType = model === "enps" ? "enps" : "survey";
  const [periodFieldOptions, setPeriodFieldOptions] = useState<
    { label: string; value: "morning" | "afternoon" | "night" }[]
  >([
    {
      label: "Manhã (08h-12h)",
      value: "morning",
    },
    {
      label: "Tarde (12h-18h)",
      value: "afternoon",
    },
    {
      label: "Noite (18h-22h)",
      value: "night",
    },
  ]);
  const [recurrenceMode, setRecurrenceMode] = useState<boolean | undefined>(
    recurrence ? true : startDate?.date && startDate?.period ? false : undefined
  );
  const [interactionDeadlineType, setInteractionDeadlineType] = useState<
    "next" | "after"
  >();

  useEffect(() => {
    if (model === "engaja" && recurrenceMode !== false)
      setRecurrenceMode(false);
  }, [model]);

  const [endType, setEndType] = useState<"never" | "date" | "occurrence">();

  const [daysOfTheWeek, setDaysOfTheWeek] = useState<
    DaysPickerInterface["days"]
  >([
    {
      label: "Segunda-feira",
      value: "monday",
      checked: false,
    },
    {
      label: "Terça-feira",
      value: "tuesday",
      checked: false,
    },
    {
      label: "Quarta-feira",
      value: "wednesday",
      checked: false,
    },
    {
      label: "Quinta-feira",
      value: "thursday",
      checked: false,
    },
    {
      label: "Sexta-feira",
      value: "friday",
      checked: false,
    },
    {
      label: "Sábado",
      value: "saturday",
      checked: false,
    },
    {
      label: "Domingo",
      value: "sunday",
      checked: false,
    },
  ]);

  useEffect(() => {
    if (recurrence) {
      if (recurrence?.occurrenceLimit) {
        if (recurrence?.occurrenceLimit === -1) {
          setEndType("never");
          return;
        }
        if (recurrence?.occurrenceLimit > 0) {
          setEndType("occurrence");
          return;
        }
      }

      if (recurrence?.endDate) setEndType("date");
    }
  }, []);

  useEffect(() => {
    if (recurrence) {
      if (recurrence?.interactionDeadlineConfig?.nextMode) {
        setInteractionDeadlineType("next");
        return;
      }

      if (
        recurrence?.interactionDeadlineConfig?.calculatedDate?.multiplier &&
        recurrence?.interactionDeadlineConfig?.calculatedDate?.period
      ) {
        setInteractionDeadlineType("after");
        return;
      }
    }
  }, []);

  useEffect(() => {
    if (recurrence?.daysOfTheWeek?.length) {
      setDaysOfTheWeek(
        daysOfTheWeek?.map(({ label, value }) => ({
          label,
          value,
          checked: recurrence?.daysOfTheWeek?.includes(value) ? true : false,
        }))
      );
    }
  }, []);

  useEffect(() => {
    if (recurrence) {
      onChange({
        recurrenceMode,
        interactionDeadline,
        startDate,
        recurrence: {
          ...recurrence,
          daysOfTheWeek:
            daysOfTheWeek
              ?.filter(({ checked }) => checked)
              ?.map(({ value }) => value) || [],
        },
      });
    }
  }, [daysOfTheWeek]);

  useEffect(() => {
    if (startDate?.date && !disabled) {
      const diff = dayjs(dayjs()?.format("YYYY-MM-DD")).diff(
        dayjs(startDate?.date)?.format("YYYY-MM-DD"),
        "day"
      );

      if (diff >= 0) {
        const hour = parseInt(dayjs()?.format("HH"));

        if (hour < 12) {
          setPeriodFieldOptions([
            {
              label: "Imediatamente",
              value: "morning",
            },
            {
              label: "Tarde (12h-18h)",
              value: "afternoon",
            },
            {
              label: "Noite (18h-22h)",
              value: "night",
            },
          ]);
          return;
        }

        if (hour < 18) {
          setPeriodFieldOptions([
            {
              label: "Imediatamente",
              value: "afternoon",
            },
            {
              label: "Noite (18h-22h)",
              value: "night",
            },
          ]);
          return;
        }

        setPeriodFieldOptions([
          {
            label: "Imediatamente",
            value: "night",
          },
        ]);
        return;
      } else {
        setPeriodFieldOptions([
          {
            label: "Manhã (08h-12h)",
            value: "morning",
          },
          {
            label: "Tarde (12h-18h)",
            value: "afternoon",
          },
          {
            label: "Noite (18h-22h)",
            value: "night",
          },
        ]);
      }
    }
  }, [startDate?.date]);

  useEffect(() => {
    if (startDate?.period && !disabled) {
      const selectedPeriod = periodFieldOptions?.find(
        ({ value }) => value === startDate?.period
      );

      if (!selectedPeriod)
        onChange({
          recurrenceMode,
          interactionDeadline,
          recurrence,
          startDate: {
            ...startDate,
            period: undefined,
          },
        });
    }
  }, [periodFieldOptions]);

  const getStartDate = () => {
    if (startDate?.date) {
      const now = dayjs();
      const startDt = dayjs(startDate?.date);

      if (dayjs(now.format("YYYY-MM-DD")).diff(startDt, "day") < 0)
        return startDt;
    }
    return dayjs(new Date());
  };

  if (hide) return <></>;

  return (
    <StepContainer>
      <StepDescription
        model={model}
        title="Envio"
        description="Configure como deseja enviar."
      />
      <StyleContainer>
        <Box>
          <Typography
            variant="headline7"
            color="var(--color-neutral-30)"
            fontWeight={700}
          >
            Configurações do envio
          </Typography>
          <Divider spacing="quark" />
          <Typography
            variant="body4"
            color="var(--color-neutral-50)"
            fontWeight={400}
          >
            {model === "engaja"
              ? "Escolha a data de envio e o período em que a sua pesquisa será enviada"
              : `Escolha se o envio será realizado uma única vez ou configure a
            recorrência.`}
          </Typography>
          <Divider spacing="s1" />
          {/* {model === "engaja" && (
            <>
              <NotificationCard.Root type={"info"} variant={"outlined"}>
                <NotificationCard.Icon iconName={"IconInfoCircle"} />
                <NotificationCard.WrapperTexts>
                  <NotificationCard.Title>
                    Data de encerramento da pesquisa
                  </NotificationCard.Title>
                  <NotificationCard.Subtitle>
                    A pesquisa estará{" "}
                    <strong>
                      disponível para os colaboradores até{" "}
                      {dayjs(interactionDeadline)?.format("DD/MM/YYYY")}
                    </strong>
                    . Por isso, ao programar a data de envio, leve em conta o
                    tempo necessário para que todos possam responder.
                    Geralmente,{" "}
                    <strong>
                      cada pessoa leva cerca de 15 minutos para completar a
                      pesquisa.
                    </strong>
                  </NotificationCard.Subtitle>
                </NotificationCard.WrapperTexts>
              </NotificationCard.Root>
              <Divider spacing="s1" />
            </>
          )} */}
          {model === "engaja" ? null : (
            <Row>
              <BoxAdornment
                style={{ width: "100%" }}
                description={
                  <>
                    <Typography
                      variant="body3"
                      color="var(--color-neutral-30)"
                      fontWeight={600}
                    >
                      Envio único
                    </Typography>
                  </>
                }
                leftAdornment={
                  <Radio
                    disabled={disabled}
                    checked={recurrenceMode === false}
                    onChange={() => {
                      segment({
                        track: `people_strategic_hr_engagement_company_${engagementSegmentType}_sendinfos_singlesend_clicked`,
                      });

                      onChange({
                        recurrenceMode: !recurrenceMode,

                        interactionDeadline,
                        startDate,
                        recurrence,
                      });
                      setRecurrenceMode(false);
                    }}
                  />
                }
              />
              <Divider spacing="xs1" type="horizontal" />
              <BoxAdornment
                style={{ width: "100%" }}
                description={
                  <>
                    <Typography
                      variant="body3"
                      color="var(--color-neutral-30)"
                      fontWeight={600}
                    >
                      Envio recorrente
                    </Typography>
                  </>
                }
                leftAdornment={
                  <Radio
                    disabled={disabled}
                    checked={recurrenceMode}
                    onChange={() => {
                      segment({
                        track: `people_strategic_hr_engagement_company_${engagementSegmentType}_sendinfos_recurringsend_clicked`,
                      });

                      onChange({
                        recurrenceMode: !recurrenceMode,
                        interactionDeadline,
                        startDate,
                        recurrence: {
                          ...recurrence,
                          repeat: recurrence?.repeat || 1,
                        },
                      });
                      setRecurrenceMode(true);
                    }}
                  />
                }
              />
            </Row>
          )}
          {recurrenceMode !== undefined && model !== "engaja" ? (
            <>
              <Divider spacing="xs1" />
              <LineSeparator />
            </>
          ) : null}
          {recurrenceMode ? (
            <>
              <Divider spacing="xs1" />
              <Row>
                <Typography
                  variant="body3"
                  color="var(--color-neutral-30)"
                  fontWeight={600}
                  minWidth="105px"
                >
                  Repetir a cada
                </Typography>
                <Divider spacing="xxxs" type="horizontal" />
                <CounterField
                  disabled={disabled}
                  value={recurrence?.repeat || 1}
                  min={1}
                  onChange={(e) =>
                    onChange({
                      recurrenceMode,

                      interactionDeadline,
                      startDate,
                      recurrence: { ...recurrence, repeat: e },
                    })
                  }
                />
                <Divider spacing="xs1" type="horizontal" />
                <SelectField
                  disabled={disabled}
                  className="step-dispatch-field"
                  label={"Frequência"}
                  fullWidth
                  options={[
                    {
                      label: "Dia",
                      value: "day",
                    },
                    {
                      label: "Semana",
                      value: "week",
                    },
                    {
                      label: "Mês",
                      value: "month",
                    },
                    {
                      label: "Ano",
                      value: "year",
                    },
                  ]}
                  onSelectChange={(_: any, option) => {
                    segment({
                      track: `company_engagement_${engagementSegmentType}send_recurringsend_frequency_${
                        option?.value === "day" ? "daily" : `${option?.value}ly`
                      }_clicked`,
                    });

                    onChange({
                      recurrenceMode,

                      interactionDeadline,
                      startDate,
                      recurrence: { ...recurrence, frequency: option?.value },
                    });
                  }}
                  value={recurrence?.frequency}
                />
              </Row>
            </>
          ) : null}
          {recurrenceMode && recurrence?.frequency === "week" ? (
            <>
              <Divider spacing="xs1" />
              <Row>
                <Typography
                  variant="headline9"
                  fontWeight={700}
                  color="var(--color-neutral-30)"
                >
                  Selecione os dias da semana
                </Typography>
              </Row>
              <Divider spacing="xs1" />
              <Row>
                <DaysPicker
                  disabled={disabled}
                  days={daysOfTheWeek}
                  onChange={(days, value) => {
                    segment({
                      track: `company_engagement_${engagementSegmentType}send_recurringsend_weekly_${value?.value}_selected`,
                    });

                    setDaysOfTheWeek(days);
                  }}
                />
              </Row>
            </>
          ) : null}
          {model !== "engaja" ? <Divider spacing="xs1" /> : null}
          {recurrenceMode !== undefined ? (
            <>
              {recurrenceMode ? (
                <Row>
                  <Typography
                    variant="headline9"
                    fontWeight={700}
                    color="var(--color-neutral-30)"
                  >
                    Início da recorrência
                  </Typography>
                </Row>
              ) : model !== "engaja" ? (
                <>
                  <Row>
                    <Typography
                      variant="headline9"
                      fontWeight={700}
                      color="var(--color-neutral-30)"
                    >
                      Envio e prazo de respostas
                    </Typography>
                  </Row>
                  <Row>
                    <Typography
                      variant="body4"
                      fontWeight={400}
                      color="var(--color-neutral-50)"
                    >
                      A pesquisa estará disponivel pra respostas durante o
                      período abaixo.
                    </Typography>
                  </Row>
                </>
              ) : null}
            </>
          ) : null}
          {recurrenceMode !== undefined ? (
            <>
              <Divider spacing="xs1" />
              <Row>
                <DatePicker
                  key={"step-dispatch-start-date-field-1"}
                  disabled={disabled}
                  className="step-dispatch-field"
                  fromDate={dayjs(new Date())}
                  toDate={
                    interactionDeadline ? dayjs(interactionDeadline) : undefined
                  }
                  label="Data de envio"
                  onDateChange={(value: Dayjs | undefined) => {
                    const date = dayjs(value)?.format("YYYY-MM-DD");

                    if (date && date !== "Invalid Date")
                      onChange({
                        recurrenceMode,

                        interactionDeadline,
                        recurrence,
                        startDate: {
                          ...startDate,
                          date: dayjs(value)?.format("YYYY-MM-DD"),
                        },
                      });
                  }}
                  value={startDate?.date}
                />
                <Divider spacing="xs1" type="horizontal" />
                <SelectField
                  disabled={disabled}
                  className="step-dispatch-field"
                  label={"Período"}
                  fullWidth
                  options={periodFieldOptions}
                  onSelectChange={(_: any, option) => {
                    const p = option?.value;

                    let sPeriod = "immediately";
                    if (option?.label !== "Imediatamente") {
                      sPeriod = p;
                      if (p === "night") sPeriod = "evening";
                    }

                    segment({
                      track: `company_engagement_${engagementSegmentType}send_${
                        recurrenceMode ? "recurring" : "single"
                      }send_sendtime_${sPeriod}_clicked`,
                    });

                    onChange({
                      recurrenceMode,

                      interactionDeadline,
                      recurrence,
                      startDate: {
                        ...startDate,
                        period: p,
                      },
                    });
                  }}
                  value={startDate?.period}
                />
              </Row>
            </>
          ) : null}
          {recurrenceMode !== undefined ? (
            <>
              {recurrenceMode ? null : (
                <>
                  <Divider spacing="xs1" />
                  <Row>
                    <DatePicker
                      disabled={disabled}
                      className="step-dispatch-field"
                      fromDate={getStartDate()}
                      label="Prazo de resposta"
                      style={{ width: "100%" }}
                      onDateChange={(value: Dayjs | undefined) => {
                        const date = dayjs(value)?.format("YYYY-MM-DD");

                        if (date && date !== "Invalid Date")
                          onChange({
                            recurrenceMode,

                            recurrence,
                            startDate,
                            interactionDeadline:
                              dayjs(value)?.format("YYYY-MM-DD"),
                          });
                      }}
                      value={interactionDeadline}
                    />
                  </Row>
                </>
              )}
            </>
          ) : null}

          {recurrenceMode && recurrence?.frequency ? (
            <>
              <Divider spacing="xs1" />
              <Row>
                <Typography
                  variant="headline9"
                  fontWeight={700}
                  color="var(--color-neutral-30)"
                >
                  Fim da recorrência
                </Typography>
              </Row>
              <Divider spacing="xs1" />
              <Row>
                <BoxAdornment
                  style={{ width: "100%" }}
                  description={
                    <>
                      <Typography
                        variant="body3"
                        color="var(--color-neutral-30)"
                        fontWeight={600}
                      >
                        Nunca termina
                      </Typography>
                    </>
                  }
                  leftAdornment={
                    <Radio
                      checked={endType === "never"}
                      onChange={() => {
                        segment({
                          track: `company_engagement_${engagementSegmentType}send_recurringsend_${
                            recurrence?.frequency === "day"
                              ? "daily"
                              : `${recurrence?.frequency}ly`
                          }recurringends_never_selected`,
                        });

                        onChange({
                          recurrenceMode,

                          interactionDeadline,
                          startDate,
                          recurrence: {
                            ...recurrence,
                            endDate: undefined,
                            occurrenceLimit: -1,
                          },
                        });

                        setEndType("never");
                      }}
                    />
                  }
                />
              </Row>
              <Divider spacing="xs1" />
              <Row>
                <BoxAdornment
                  style={{ width: "100%" }}
                  description={
                    <Row>
                      <Typography
                        variant="body3"
                        color="var(--color-neutral-30)"
                        fontWeight={600}
                        minWidth="86px"
                      >
                        Termina em
                      </Typography>
                      <Divider spacing="xxxs" type="horizontal" />
                      <DatePicker
                        disabled={endType !== "date"}
                        fromDate={dayjs(new Date())}
                        label="Data"
                        onDateChange={(value: Dayjs | undefined) => {
                          const date = dayjs(value)?.format("YYYY-MM-DD");

                          if (date && date !== "Invalid Date") {
                            if (
                              recurrence?.endDate &&
                              recurrence?.endDate === date
                            )
                              return;

                            onChange({
                              recurrenceMode,

                              interactionDeadline,
                              startDate,
                              recurrence: {
                                ...recurrence,
                                occurrenceLimit: undefined,
                                endDate: date,
                              },
                            });
                          }
                        }}
                        value={recurrence?.endDate}
                      />
                    </Row>
                  }
                  leftAdornment={
                    <Radio
                      checked={endType === "date"}
                      onChange={() => {
                        segment({
                          track: `company_engagement_${engagementSegmentType}send_recurringsend_${
                            recurrence?.frequency === "day"
                              ? "daily"
                              : `${recurrence?.frequency}ly`
                          }recurringends_specificdate_selected`,
                        });

                        onChange({
                          recurrenceMode,
                          interactionDeadline,
                          startDate,
                          recurrence: {
                            ...recurrence,
                            occurrenceLimit: undefined,
                            endDate: undefined,
                            interactionDeadlineConfig: {
                              ...(recurrence?.interactionDeadlineConfig || {}),
                              nextMode: undefined,
                            },
                          },
                        });
                        setEndType("date");
                        if (interactionDeadlineType === "next")
                          setInteractionDeadlineType(undefined);
                      }}
                    />
                  }
                />
              </Row>
              <Divider spacing="xs1" />
              <Row>
                <BoxAdornment
                  style={{ width: "100%" }}
                  description={
                    <Row>
                      <Typography
                        variant="body3"
                        color="var(--color-neutral-30)"
                        fontWeight={600}
                      >
                        Termina após
                      </Typography>
                      <Divider spacing="xxxs" type="horizontal" />
                      <CounterField
                        disabled={endType !== "occurrence"}
                        value={
                          recurrence?.occurrenceLimit &&
                          recurrence?.occurrenceLimit > 0
                            ? recurrence?.occurrenceLimit
                            : 1
                        }
                        min={1}
                        onChange={(e) =>
                          onChange({
                            recurrenceMode,
                            interactionDeadline,
                            startDate,
                            recurrence: {
                              ...recurrence,
                              endDate: undefined,
                              occurrenceLimit: e,
                            },
                          })
                        }
                      />
                      <Divider spacing="xxxs" type="horizontal" />
                      <Typography
                        variant="body3"
                        color="var(--color-neutral-30)"
                        fontWeight={600}
                      >
                        ocorrências
                      </Typography>
                    </Row>
                  }
                  leftAdornment={
                    <Radio
                      checked={endType === "occurrence"}
                      onChange={() => {
                        segment({
                          track: `company_engagement_${engagementSegmentType}send_recurringsend_${
                            recurrence?.frequency === "day"
                              ? "daily"
                              : `${recurrence?.frequency}ly`
                          }recurringends_endAfter_selected`,
                        });

                        onChange({
                          recurrenceMode,

                          interactionDeadline,
                          startDate,
                          recurrence: {
                            ...recurrence,
                            endDate: undefined,
                            occurrenceLimit: 1,
                            interactionDeadlineConfig: {
                              ...(recurrence?.interactionDeadlineConfig || {}),
                              nextMode: undefined,
                            },
                          },
                        });
                        setEndType("occurrence");
                        if (interactionDeadlineType === "next")
                          setInteractionDeadlineType(undefined);
                      }}
                    />
                  }
                />
              </Row>
            </>
          ) : null}
        </Box>
        {recurrenceMode && recurrence?.frequency ? (
          <>
            <Divider spacing="xs2" />
            <Box>
              <Row>
                <Typography
                  variant="headline8"
                  fontWeight={700}
                  color="var(--color-neutral-30)"
                >
                  Período de coleta de respostas
                </Typography>
              </Row>
              <Row>
                <Typography
                  variant="body4"
                  fontWeight={400}
                  color="var(--color-neutral-50)"
                >
                  Configure até quando a pesquisa deve receber respostas. Após o
                  limite, a pesquisa será fechada e novas respostas não serão
                  aceitas.
                </Typography>
              </Row>
              <Divider spacing="xs1" />
              <Row>
                <Typography
                  variant="headline9"
                  fontWeight={700}
                  color="var(--color-neutral-30)"
                >
                  Aceitar respostas:
                </Typography>
              </Row>
              <Divider spacing="xs1" />
              <Row>
                <BoxAdornment
                  style={{ width: "100%" }}
                  description={
                    <>
                      <Typography
                        variant="body3"
                        color="var(--color-neutral-30)"
                        fontWeight={600}
                      >
                        Até o próximo envio
                      </Typography>
                    </>
                  }
                  leftAdornment={
                    <Radio
                      disabled={
                        recurrence?.occurrenceLimit === -1 ? false : true
                      }
                      checked={interactionDeadlineType === "next"}
                      onChange={() => {
                        segment({
                          track: `company_engagement_${engagementSegmentType}send_recurringsend_${
                            recurrence?.frequency === "day"
                              ? "daily"
                              : `${recurrence?.frequency}ly`
                          }recurringends_never_selected`,
                        });

                        onChange({
                          recurrenceMode,

                          interactionDeadline,
                          startDate,
                          recurrence: {
                            ...recurrence,
                            interactionDeadlineConfig: {
                              calculatedDate: undefined,
                              nextMode: true,
                            },
                          },
                        });
                        setInteractionDeadlineType("next");
                      }}
                    />
                  }
                />
              </Row>
              <Divider spacing="xs1" />
              <Row>
                <BoxAdornment
                  style={{ width: "100%" }}
                  description={
                    <Row>
                      <Typography
                        variant="body3"
                        color="var(--color-neutral-30)"
                        fontWeight={600}
                        minWidth={"26px"}
                      >
                        Até
                      </Typography>
                      <Divider spacing="xxxs" type="horizontal" />
                      <CounterField
                        disabled={interactionDeadlineType !== "after"}
                        value={
                          recurrence?.interactionDeadlineConfig?.calculatedDate
                            ?.multiplier || 1
                        }
                        min={1}
                        onChange={(e) =>
                          onChange({
                            recurrenceMode,

                            interactionDeadline,
                            startDate,
                            recurrence: {
                              ...recurrence,
                              interactionDeadlineConfig: {
                                calculatedDate: {
                                  ...recurrence?.interactionDeadlineConfig
                                    ?.calculatedDate,
                                  multiplier: e,
                                },
                                nextMode: false,
                              },
                            },
                          })
                        }
                      />
                      <Divider spacing="xxxs" type="horizontal" />
                      <SelectField
                        fullWidth
                        disabled={interactionDeadlineType !== "after"}
                        label={"Período"}
                        className="step-dispatch-frequency-select"
                        style={{ width: "200px" }}
                        options={[
                          {
                            label: "Dia",
                            value: "day",
                          },
                          {
                            label: "Semana",
                            value: "week",
                          },
                          {
                            label: "Mês",
                            value: "month",
                          },
                        ]}
                        onSelectChange={(_: any, option) => {
                          onChange({
                            recurrenceMode,

                            interactionDeadline,
                            startDate,
                            recurrence: {
                              ...recurrence,
                              interactionDeadlineConfig: {
                                calculatedDate: {
                                  ...recurrence?.interactionDeadlineConfig
                                    ?.calculatedDate,
                                  period: option?.value,
                                },
                                nextMode: false,
                              },
                            },
                          });
                        }}
                        value={
                          recurrence?.interactionDeadlineConfig?.calculatedDate
                            ?.period
                        }
                      />
                      <Divider spacing="xxxs" type="horizontal" />
                      <Typography
                        variant="body3"
                        color="var(--color-neutral-30)"
                        fontWeight={600}
                        minWidth="189px"
                      >
                        após a data de cada envio
                      </Typography>
                    </Row>
                  }
                  leftAdornment={
                    <Radio
                      checked={interactionDeadlineType === "after"}
                      onChange={() => {
                        segment({
                          track: `company_engagement_${engagementSegmentType}send_recurringsend_${
                            recurrence?.frequency === "day"
                              ? "daily"
                              : `${recurrence?.frequency}ly`
                          }collectanswers_untilspecifictime_selected`,
                        });

                        onChange({
                          recurrenceMode,

                          interactionDeadline,
                          startDate,
                          recurrence: {
                            ...recurrence,
                            interactionDeadlineConfig: {
                              calculatedDate: {
                                multiplier: 1,
                                period: undefined,
                              },
                              nextMode: false,
                            },
                          },
                        });
                        setInteractionDeadlineType("after");
                      }}
                    />
                  }
                />
              </Row>
            </Box>
          </>
        ) : null}
      </StyleContainer>
    </StepContainer>
  );
};

export { StepDispatch };
