import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useTheme } from "styled-components";
import { getAccessTokenPayloadSync } from "@flash-tecnologia/hros-web-utility";

import { PageTemplate } from "./components/PageTemplate";
import {
  Checkbox,
  DatePicker,
  Icons,
  LinkButton,
  Radio,
  Skeleton,
  TextField,
  dayjs,
  useMediaQuery,
} from "@flash-tecnologia/hros-web-ui-v2";

import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween);

import {
  StyledText,
  StyledTitle,
  dispatchToast,
  segment,
  alert,
} from "../../utils";

import {
  CheckboxContainer,
  Divider,
  Dot,
  ErrorAsterisk,
  FlexCenter,
  FlexRow,
  IconButtonContainer,
  LogoContainer,
  ProgressBarContainer,
  RadioContainer,
  SectionContainer,
  SectionHeaderContainer,
  StyledSelectField,
} from "./styles";
import { concat } from "lodash";
import { RadioGroup } from "@mui/material";

import { Scale } from "./components/Scale";
import { OutOfDeadline } from "./components/OutOfDeadline";
import { SurveyAlreadyAnswered } from "./components/SurveyAlreadyAnswered";
import { EngajaModal } from "./components/EngajaModal";

import { WarningSendSurveyModal } from "./components/WarningSendSurveyModal";
import { SendSurveyModal } from "./components/SendSurveyModal";

import Logo from "../../assets/survey_flash_logo.svg";
import { RouterOutput, trpc } from "@api/client";
import { ConfidentialityInfo } from "./components/ConfidentialityInfo";
import { SurveyReview } from "./components/SurveyReview";
import { SurveyInfo } from "./components/SurveyInfo";
import { DraftConfirmModal } from "./components/DraftConfirmModal";

export default () => {
  const { step = "1", id } = useParams();

  const engagementId = id ?? "";

  const isMobile = useMediaQuery("(max-width: 768px)");

  const utils = trpc.useContext();

  const theme = useTheme() as any;

  const navigate = useNavigate();
  const { employeeId } = getAccessTokenPayloadSync("hrosAccessToken");

  const topRef = useRef<HTMLDivElement>(null);

  const [surveyData, setSurveyData] = useState<
    RouterOutput["getEmployeeSurvey"]["survey"] | undefined
  >();
  const [questionsAnswered, setQuestionsAnswered] = useState<any>([]);
  const [monitorings, setMonitorings] = useState<any>(null);
  const [warningSendSurvey, setWarningSendSurvey] = useState<any>(false);
  const [sendSurvey, setSendSurvey] = useState<any>(false);
  const [disableForm, setDisableForm] = useState<any>(false);
  const [hasAnswerSurvey, setHasAnswerSurvey] = useState<any>(false);
  const [displayedEngajaModalIndices, setDisplayedEngajaModalIndices] =
    useState<number[]>([]);
  const [displaySurveyReview, setDisplaySurveyReview] = useState(false);
  const [displaySurveyInfo, setDisplaySurveyInfo] = useState(true);
  const [reviewSections, setReviewSections] = useState<any[]>([]);
  const [hiddenIndexes, setHiddenIndexes] = useState<number[]>([]);
  const [displayDraftConfirmModal, setDisplayDraftConfirmModal] =
    useState(false);

  const routesBread = [
    {
      label: "Minhas Pesquisas",
      route: "/engagement/my-surveys",
    },
    {
      label: disableForm ? "Ver resposta" : "Responder pesquisa",
      route: "",
    },
  ];

  const steps = surveyData?.sections?.map((s) => s?.title);

  const {
    data: getEmployeeSurveyResult,
    refetch: getEmployeeSurveyRefetch,
    isFetching: getEngagementByIdLoading,
  } = trpc.getEmployeeSurvey.useQuery(
    {
      id: engagementId || "",
    },
    {
      retry: false,
      enabled: !!engagementId,
      onSuccess: (data) => {
        if (data?.survey?.sections?.length)
          setReviewSections(data?.survey?.sections || []);
        if (!surveyData?._id) setSurveyData(data?.survey);
      },
      refetchOnWindowFocus: false,
      onError: (e: any) => {
        console.log("error: ", e);
      },
    }
  );

  const { isFetching: getEngagementMonitoringByIdLoading } =
    trpc.getEmployeeMonitoringsByEngagementId.useQuery(
      {
        id: engagementId || "",
        employeeId: employeeId || "",
      },
      {
        retry: false,
        enabled: !!engagementId && !!employeeId,
        onSuccess: (data) => {
          const { monitorings = [] } =
            data?.[0] || ({ monitorings: [], isDraft: false } as any);

          const filteredMonitorings = monitorings?.filter(
            (m) =>
              m?.sections?.length && (m?.type === "react" || m?.type === "enps")
          );

          if (filteredMonitorings?.length) {
            setDisableForm(!!!filteredMonitorings?.[0]?.draft);
            setHasAnswerSurvey(!!!filteredMonitorings?.[0]?.draft);
            setMonitorings(filteredMonitorings?.[0]);
          } else {
            setMonitorings({});
            setHasAnswerSurvey(false);
          }
        },
        refetchOnWindowFocus: false,
        onError: (e: any) => {
          console.log("err: ", e);
        },
      }
    );

  const { isLoading: isSettingMonitoring, mutate: setMonitoringMutate } =
    trpc.setMonitoring.useMutation({
      onError: (e: any) => {
        if (
          e?.data?.error === "ENGAGEMENT_NOT_EXISTS_ERROR" ||
          e?.data?.error === "EMPLOYEE_NOT_EXISTS_ERROR"
        ) {
          dispatchToast({
            type: "warning",
            content:
              "Por favor, verifique se está na empresa correta e se está como perfil de colaborador.",
          });
          navigate(`/home`);
          return;
        }
        dispatchToast({
          type: "error",
          content:
            "Ocorreu um erro. Por favor, verifique se preencheu os campos obrigatórios.",
        });
      },
    });

  const { isLoading: isLoadingAnswerSurvey, mutate: answerSurveyMutation } =
    trpc.answerSurvey.useMutation({
      onError: () => {
        dispatchToast({
          type: "error",
          content:
            "Ocorreu um erro. Por favor, verifique se preencheu os campos obrigatórios.",
        });
      },
    });

  const sectionIndex = parseInt(Number(step) < 0 ? "0" : step);

  const sectionsQuantities = surveyData?.sections?.length ?? 0;
  const questionsQuantities =
    surveyData?.sections?.[sectionIndex]?.questions?.length ?? 0;
  const allQuestionsQuantities =
    surveyData?.sections?.reduce(
      (prev, curr) => (prev = prev + (curr?.questions?.length ?? 0) || 0),
      0
    ) ?? 0;
  const foundSurvey = surveyData?.sections?.[sectionIndex];

  const allRequiredQuestions =
    surveyData?.sections?.[sectionIndex]?.questions?.filter(
      (q) => q.required
    ) || [];

  const disableNextButton = displaySurveyInfo
    ? false
    : allRequiredQuestions?.length
    ? !allRequiredQuestions?.every(
        (q) =>
          !!questionsAnswered?.find((a) => {
            const existsJustification = typeof a?.justification == "string";

            const hasFilledJustification = existsJustification
              ? a?.justification
              : true;

            const hasValue = a?.value?.toString();
            const validation = existsJustification
              ? hasFilledJustification
              : hasValue;

            return a.id === q.id && !!validation;
          })
      )
    : false;

  const routes = {
    pageSurveyAnswer: `/engagement/survey/answer/:step/${engagementId}`,
  };

  useEffect(() => {
    if (engagementId)
      setMonitoringMutate({
        engagementId: engagementId,
        type: "visualized",
      });
  }, [engagementId]);

  useEffect(() => {
    if (parseInt(step) + 1 > sectionsQuantities || parseInt(step) < 0) {
      const stepIndex =
        parseInt(step) + 1 > sectionsQuantities ? sectionsQuantities - 1 : 0;
      navigate(
        routes.pageSurveyAnswer.replace(
          ":step",
          (stepIndex < 0 ? 0 : stepIndex)?.toString()
        )
      );
    }

    if (monitorings) {
      const currentSection = surveyData?.sections?.[sectionIndex];
      const findSectionIndex = monitorings?.sections?.findIndex(
        (se) => currentSection?.id === se?.id
      );

      const questionsBySectionIndex = monitorings?.sections?.[
        findSectionIndex
      ]?.questions?.map((q) => {
        let params = { ...q };
        if (q.justification == "") params = { ...params, justification: true };
        return params;
      });

      setQuestionsAnswered(questionsBySectionIndex || []);
    }
  }, [step, sectionsQuantities, monitorings, surveyData, sectionIndex]);

  useEffect(() => {
    if (monitorings) {
      const array = surveyData?.sections?.map((s) =>
        s?.questions?.every((q) => {
          const findSectionIndex = monitorings?.sections?.findIndex(
            (se) => s.id === se.id
          );
          const questionsBySectionIndex =
            monitorings?.sections?.[findSectionIndex]?.questions;

          const findQuestions = questionsBySectionIndex?.find(
            (qu) => qu.id === q.id
          );

          return (
            (q.required &&
              (findQuestions?.value?.toString() ||
                findQuestions?.justification)) ||
            !q.required
          );
        })
      );

      const findFalse = array?.findIndex((a) => !a) ?? 0;
      if (findFalse >= 0 && parseInt(step) > findFalse) {
        navigate(routes.pageSurveyAnswer.replace(":step", String(findFalse)));
        return;
      }
    }
  }, [surveyData, monitorings]);

  useEffect(() => {
    if (displaySurveyReview) getEmployeeSurveyRefetch();
  }, [displaySurveyReview]);

  useEffect(() => {
    if (hiddenIndexes?.length === 0) {
      const currentStep = parseInt(step);
      if (currentStep === 0) {
        if (getEmployeeSurveyResult?.details?.status === "started") {
          const sectionIndex =
            getEmployeeSurveyResult?.survey?.sections?.reduce(
              (acc, section, sectionIndex) => {
                section?.questions?.forEach((q) => {
                  const question: any = q;
                  const userAnswerValue = question?.userAnswer?.value;
                  const justification = question?.userAnswer?.justification;

                  if (![undefined, null, ""]?.includes(userAnswerValue)) {
                    acc = sectionIndex;
                    return acc;
                  }

                  if (![undefined, null, ""]?.includes(justification)) {
                    acc = sectionIndex;
                    return acc;
                  }
                });

                return acc;
              },
              -1
            );

          if (sectionIndex !== -1 && typeof sectionIndex === "number") {
            setDisplaySurveyInfo(false);
            setDisplaySurveyReview(false);
            setHiddenIndexes([0, 1, 7, 13]?.filter((i) => sectionIndex >= i));

            navigate(
              routes.pageSurveyAnswer.replace(":step", sectionIndex.toString())
            );
          }
        }
      }
    }
  }, [getEmployeeSurveyResult]);

  const canAnswerSurvey =
    dayjs(surveyData?.startDate?.date)?.isValid() &&
    dayjs(surveyData?.interactionDeadline)?.isValid() &&
    dayjs().isBetween(
      surveyData?.startDate?.date,
      surveyData?.interactionDeadline,
      "d",
      "[]"
    );

  const isOutOfDeadline =
    !surveyData?.interactionDeadline || canAnswerSurvey || hasAnswerSurvey
      ? false
      : true;

  const questionsAnsweredQt =
    questionsAnswered?.filter(
      (q) =>
        (Array.isArray(q?.value) && q?.length) ||
        (typeof q?.justification == "string" && q?.justification) ||
        q?.value?.toString()
    )?.length || 0;

  const progressValue = (questionsAnsweredQt / questionsQuantities) * 100;

  const shouldDisplayEngajaModal = () => {
    const validIndices = [0, 1, 7, 13];
    return (
      validIndices.includes(sectionIndex) &&
      !displayedEngajaModalIndices?.includes(sectionIndex)
    );
  };

  if (hasAnswerSurvey) return <SurveyAlreadyAnswered />;

  return isOutOfDeadline ? (
    <OutOfDeadline />
  ) : (
    <>
      <DraftConfirmModal
        loading={isLoadingAnswerSurvey || false}
        visible={displayDraftConfirmModal}
        onCancel={() => navigate("/engagement/my-surveys")}
        interactionDeadline={
          getEmployeeSurveyResult?.survey?.interactionDeadline
        }
        onClick={() => {
          const questions = questionsAnswered
            ?.filter((f) => f?.value !== undefined || f?.justification)
            .map((q) => {
              let params = { ...q };
              if (typeof q.justification === "boolean")
                params = { ...params, justification: "" };
              return params;
            });

          if (!disableForm) {
            answerSurveyMutation(
              {
                surveyId: engagementId,
                draft: true,
                sections: [
                  {
                    id: foundSurvey?.id ?? "",
                    questions: questions,
                  },
                ],
              },
              {
                onSuccess: () => {
                  alert("Respostas salvas com sucesso!", "success");
                  navigate("/engagement/my-surveys");
                },
              }
            );
          }
        }}
      />
      {getEmployeeSurveyResult?.survey?.model === "engaja" &&
      (sectionIndex === 0 && displaySurveyInfo ? false : true) &&
      !hiddenIndexes?.includes(sectionIndex) ? (
        <EngajaModal
          dimension={sectionIndex}
          visible={shouldDisplayEngajaModal()}
          onClick={(d) =>
            setDisplayedEngajaModalIndices([...displayedEngajaModalIndices, d])
          }
        />
      ) : null}
      <PageTemplate
        routes={routesBread}
        hasHeader={true}
        hasStepper={displaySurveyInfo || displaySurveyReview ? false : true}
        stepper={{
          steps: steps as string[],
          activeStep: parseInt(step),
        }}
        isNotMobile={!!!isMobile}
        footer={{
          disable: disableForm && (steps?.length ?? 0) <= 1,
          cancelProps: {
            width: isMobile ? "100px" : "100%",
            callback: () => setDisplayDraftConfirmModal(true),
          },
          draftProps: {
            loading: isLoadingAnswerSurvey || false,
            width: isMobile ? "100px" : "100%",
            hasToShow: !displaySurveyInfo,
            callback: () => {
              const questions = questionsAnswered
                ?.filter((f) => f?.value !== undefined || f?.justification)
                .map((q) => {
                  let params = { ...q };
                  if (typeof q.justification === "boolean")
                    params = { ...params, justification: "" };
                  return params;
                });

              if (!disableForm) {
                answerSurveyMutation(
                  {
                    surveyId: engagementId,
                    draft: true,
                    sections: [
                      {
                        id: foundSurvey?.id ?? "",
                        questions: questions,
                      },
                    ],
                  },
                  {
                    onSuccess: () => {
                      alert("Respostas salvas com sucesso!", "success");
                      navigate("/engagement/my-surveys");
                    },
                  }
                );
              }
            },
          },
          goBackProps: {
            width: isMobile ? "100px" : "100%",
            hasToShow:
              parseInt(step) - 1 < 0 && displaySurveyInfo ? false : true,
            title: (
              <>
                <Icons name="IconArrowLeft" fill="transparent" />
                Voltar
              </>
            ),
            callback: () => {
              if (displaySurveyReview) {
                setDisplaySurveyReview(false);
              } else {
                const previousStep = parseInt(step) - 1;

                if (previousStep === -1 && !displaySurveyInfo) {
                  setDisplaySurveyInfo(true);
                  return;
                }

                navigate(
                  routes.pageSurveyAnswer.replace(
                    ":step",
                    previousStep.toString()
                  )
                );
                if (topRef?.current) {
                  topRef?.current?.scrollIntoView();
                }
              }
            },
          },
          confirmProps: {
            loading: isLoadingAnswerSurvey || false,
            width: isMobile ? "100px" : "100%",
            disabled:
              disableNextButton ||
              getEngagementByIdLoading ||
              isSettingMonitoring,
            hasToShow:
              disableForm && steps?.length === parseInt(step) + 1
                ? false
                : true,
            title: (
              <>
                {displaySurveyReview
                  ? "Enviar"
                  : displaySurveyInfo
                  ? "Responder"
                  : "Continuar"}
                <Icons name="IconArrowRight" fill="transparent" />
              </>
            ),
            callback: () => {
              if (displaySurveyReview) {
                setWarningSendSurvey(true);
                return;
              }

              if (displaySurveyInfo) {
                setDisplaySurveyInfo(false);
                return;
              }

              const nextStep = parseInt(step) + 1;

              const questions = questionsAnswered
                ?.filter((f) => f?.value !== undefined || f?.justification)
                .map((q) => {
                  let params = { ...q };
                  if (typeof q.justification === "boolean")
                    params = { ...params, justification: "" };
                  return params;
                });

              if (!disableForm) {
                answerSurveyMutation(
                  {
                    surveyId: engagementId,
                    draft: true,
                    sections: [
                      {
                        id: foundSurvey?.id ?? "",
                        questions: questions,
                      },
                    ],
                  },
                  {
                    onSuccess: () => {
                      utils.getEmployeeMonitoringsByEngagementId.invalidate();
                      setQuestionsAnswered([]);

                      if (steps?.length === parseInt(step) + 1) {
                        if (!disableForm) {
                          if (topRef?.current) {
                            topRef?.current?.scrollIntoView();
                          }
                          setDisplaySurveyReview(true);
                          return;
                        }
                      }

                      navigate(
                        routes.pageSurveyAnswer.replace(
                          ":step",
                          nextStep.toString()
                        )
                      );

                      if (topRef?.current) {
                        topRef?.current?.scrollIntoView();
                      }
                    },
                  }
                );
              } else {
                navigate(
                  routes.pageSurveyAnswer.replace(":step", nextStep.toString())
                );
                if (topRef?.current) {
                  topRef?.current?.scrollIntoView();
                }
              }
            },
          },
        }}
        customContainer={
          <>
            {getEngagementByIdLoading || getEngagementMonitoringByIdLoading ? (
              <Skeleton variant="rectangular" width={"100%"} height={44} />
            ) : disableForm ? (
              <></>
            ) : (
              <>
                <ProgressBarContainer progressValue={progressValue} />
                {getEmployeeSurveyResult?.survey?.anonymous ? (
                  <ConfidentialityInfo />
                ) : null}
                {displaySurveyInfo || displaySurveyReview ? null : (
                  <SectionHeaderContainer>
                    <StyledText
                      variant="body4"
                      setColor={"neutral50"}
                      style={{ marginRight: "40px", fontWeight: 600 }}
                      tag={"span"}
                    >
                      <FlexCenter>
                        Seção: {parseInt(step) + 1}/{sectionsQuantities} <Dot />{" "}
                        Pergunta
                        {questionsQuantities === 1 ? "" : "s"} respondida
                        {questionsQuantities === 1 ? "" : "s"}:{" "}
                        {questionsAnsweredQt}/{questionsQuantities}
                      </FlexCenter>
                    </StyledText>
                  </SectionHeaderContainer>
                )}
              </>
            )}
          </>
        }
        containerStyle={{ backgroundColor: theme.colors.neutral[95] }}
        containerHeaderStyle={{ backgroundColor: "#fff" }}
      >
        <div ref={topRef}>
          {displaySurveyReview ? (
            getEngagementByIdLoading ? (
              <>
                <Skeleton
                  variant="rectangular"
                  width={"100%"}
                  height={310}
                  style={{ marginTop: "40px" }}
                />
                <Skeleton
                  variant="rectangular"
                  width={"100%"}
                  height={310}
                  style={{ marginTop: "40px" }}
                />
              </>
            ) : (
              <SurveyReview
                title={getEmployeeSurveyResult?.survey?.title || ""}
                sendDate={getEmployeeSurveyResult?.survey?.sendDate || ""}
                interactionDeadline={
                  getEmployeeSurveyResult?.survey?.interactionDeadline || ""
                }
                anonymous={getEmployeeSurveyResult?.survey?.anonymous || false}
                sections={
                  reviewSections?.map(
                    (
                      { id = "", title = "", description = "", questions = [] },
                      sectionIndex
                    ) => ({
                      id,
                      title,
                      description,
                      questions:
                        questions?.map(
                          ({
                            id = "",
                            title = "",
                            answerFormat,
                            options,
                            ...rest
                          }) => {
                            let answer: string | number | string[] | undefined =
                              "";

                            const questionRest: any = rest as any;
                            const userAnswerValue =
                              questionRest?.userAnswer?.value;
                            const justification =
                              questionRest?.userAnswer?.justification;

                            if (answerFormat === "date" && userAnswerValue) {
                              answer =
                                dayjs(userAnswerValue)?.format("DD/MM/YYYY");
                            } else if (answerFormat === "text") {
                              answer = userAnswerValue;
                            } else if (
                              answerFormat === "multiple-choice" ||
                              answerFormat === "dropdown"
                            ) {
                              answer = justification
                                ? justification
                                : options?.find(
                                    (o) => o?.id === userAnswerValue
                                  )?.value || "";
                            } else if (answerFormat === "select-box") {
                              answer =
                                userAnswerValue?.map((v) => {
                                  const optionValue =
                                    options?.find((o) => o?.id === v)?.value ||
                                    "";

                                  if (optionValue) return optionValue;
                                  return;
                                }) || [];

                              if (justification && Array.isArray(answer))
                                answer = [...answer, justification];
                            } else {
                              answer = userAnswerValue;
                            }

                            return {
                              id,
                              title,
                              answer,
                              onClick: () => {
                                setDisplaySurveyReview(false);
                                navigate(
                                  routes.pageSurveyAnswer.replace(
                                    ":step",
                                    sectionIndex.toString()
                                  )
                                );

                                if (topRef?.current) {
                                  topRef?.current?.scrollIntoView();
                                }
                              },
                            };
                          }
                        ) || [],
                    })
                  ) || []
                }
              />
            )
          ) : (
            <div>
              {getEngagementByIdLoading ||
              getEngagementMonitoringByIdLoading ? (
                <>
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={310}
                    style={{ marginTop: "40px" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={310}
                    style={{ marginTop: "40px" }}
                  />
                </>
              ) : displaySurveyInfo ? (
                <SurveyInfo
                  sections={
                    reviewSections?.map(({ id = "", questions = [] }) => ({
                      id,
                      questions: questions?.map(({ id = "" }) => id) || [],
                    })) || []
                  }
                  title={getEmployeeSurveyResult?.survey?.title || ""}
                  model={getEmployeeSurveyResult?.survey?.model || "custom"}
                  anonymous={
                    getEmployeeSurveyResult?.survey?.anonymous || false
                  }
                  description={
                    getEmployeeSurveyResult?.survey?.description || ""
                  }
                  interactionDeadline={
                    getEmployeeSurveyResult?.survey?.interactionDeadline || ""
                  }
                  sendDate={getEmployeeSurveyResult?.survey?.sendDate || ""}
                />
              ) : (
                <>
                  <SectionContainer style={{ marginTop: "40px" }}>
                    <StyledTitle variant="headline7" setColor="primary">
                      {foundSurvey?.title || ""}
                    </StyledTitle>
                    <StyledText
                      variant="body4"
                      setColor={"neutral50"}
                      style={{ marginTop: "8px", fontWeight: 600 }}
                    >
                      {foundSurvey?.description || ""}
                    </StyledText>

                    {foundSurvey?.questions?.map((q, qIndex) => {
                      return (
                        <div key={qIndex} style={{ width: "100%" }}>
                          <div style={{ marginTop: "32px", width: "100%" }}>
                            {answerComponent(
                              q,
                              theme,
                              questionsAnswered,
                              setQuestionsAnswered,
                              surveyData?.type,
                              disableForm,
                              isMobile,
                              getEmployeeSurveyResult?.details?.status ===
                                "completed"
                            )}
                          </div>

                          {foundSurvey?.questions?.length !== qIndex + 1 ? (
                            <Divider style={{ marginTop: "32px" }} />
                          ) : (
                            <></>
                          )}
                        </div>
                      );
                    })}
                  </SectionContainer>

                  {disableForm ? (
                    <LogoContainer>
                      <StyledText
                        variant="body4"
                        setColor={"neutral30"}
                        style={{ fontWeight: 600 }}
                      >
                        Pesquisa criada com
                      </StyledText>
                      <Logo style={{ marginLeft: "8px" }} />
                    </LogoContainer>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </PageTemplate>
      <WarningSendSurveyModal
        isOpen={warningSendSurvey}
        onClose={() => setWarningSendSurvey(false)}
        onSubmit={() => {
          segment({
            track:
              "people_strategic_hr_engagement_employee_survey_answer_clicked",
          });
          const questions = questionsAnswered
            ?.filter((f) => f?.value !== undefined || f?.justification)
            .map((q) => {
              let params = { ...q };
              if (typeof q.justification === "boolean")
                params = { ...params, justification: "" };
              return params;
            });

          if (!disableForm)
            answerSurveyMutation(
              {
                surveyId: engagementId,
                draft: false,
                sections: [
                  {
                    id: foundSurvey?.id ?? "",
                    questions: questions,
                  },
                ],
              },
              {
                onSuccess: () => {
                  utils.getEmployeeMonitoringsByEngagementId.invalidate();
                  setQuestionsAnswered([]);

                  setWarningSendSurvey(false);
                  setSendSurvey(true);

                  navigate("/engagement/my-surveys");
                },
              }
            );
        }}
        isSettingMonitoring={isSettingMonitoring}
      />
      <SendSurveyModal
        isOpen={sendSurvey}
        onClose={() => setSendSurvey(false)}
        onSubmit={() => {
          setSendSurvey(false);
        }}
      />
    </>
  );
};

const answerComponent = (
  q: any,
  theme: any,
  questionsAnswered: any,
  setQuestionsAnswered: any,
  type: any,
  disabled: boolean,
  isMobile: boolean,
  isCompleted: boolean
) => {
  const foundAnswered = questionsAnswered?.find((f) => f?.id === q?.id);
  const foundIndex = questionsAnswered?.findIndex((f) => f?.id === q?.id);
  const hasJustificationAnswered =
    typeof foundAnswered?.justification === "string";

  const onChange = ({
    value,
    justification = undefined,
    hasToDelete = false,
  }: {
    value?: any;
    justification?: any;
    hasToDelete?: boolean;
  }) => {
    if (foundIndex < 0) {
      let params = {
        id: q.id,
      } as any;
      if (value !== undefined) params = { ...params, value };
      if (justification != undefined) params = { ...params, justification };

      const res = concat(questionsAnswered, params);
      setQuestionsAnswered(res);
      return;
    }

    const result = Array.from(questionsAnswered);
    const [removed]: any = result.splice(foundIndex, 1);
    let item = {
      ...removed,
      value: value !== undefined ? value : removed.value,
    };

    if (justification != undefined) item = { ...item, justification };
    if (!hasToDelete) result.splice(foundIndex, 0, item);

    setQuestionsAnswered(result);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <StyledTitle
          variant="headline8"
          setColor="neutral30"
          style={{ fontWeight: 700, marginBottom: "16px" }}
        >
          {q.title}
          {q.required ? <ErrorAsterisk>*</ErrorAsterisk> : <></>}
        </StyledTitle>

        {!isCompleted &&
        ["multiple-choice", "select-box", "dropdown"].includes(
          q.answerFormat
        ) ? (
          <LinkButton
            variant="neutral"
            onClick={() => {
              let params = { value: "" } as any;

              if (q?.justification === true)
                params = { ...params, justification: true };

              onChange(params);
            }}
          >
            <StyledText
              variant="caption"
              setColor={"neutral40"}
              style={{ fontWeight: 700, alignSelf: "center" }}
            >
              Limpar seleção
            </StyledText>
          </LinkButton>
        ) : (
          <></>
        )}
      </div>
      {q.answerFormat === "text" ? (
        <TextField
          label={q.title}
          fullWidth
          value={foundAnswered?.value || ""}
          disabled={disabled}
          onChange={(e: any) => {
            onChange({ value: e.target.value });
          }}
        />
      ) : q.answerFormat === "date" ? (
        <DatePicker
          label={q.title}
          value={foundAnswered?.value || ""}
          disabled={disabled}
          onDateChange={(value) => {
            const isValid = dayjs(value)?.isValid();

            if (isValid) {
              onChange({
                value: value ? dayjs(value)?.format("YYYY-MM-DD") : "",
              });
            }
          }}
        />
      ) : q.answerFormat === "multiple-choice" ? (
        <>
          <RadioGroup
            value={
              hasJustificationAnswered
                ? "justification"
                : foundAnswered?.value || ""
            }
            name="radio-buttons-group"
            onChange={(e: any) => {
              let params = { value: e.target.value } as any;

              if (q?.justification === true)
                params = { value: e.target.value, justification: true };

              if (
                e.target.value === "justification" &&
                q?.justification === true
              )
                params = { value: "", justification: "" };

              onChange(params);
            }}
          >
            {q.options.map((qo, qoIndex) => {
              return (
                <RadioContainer
                  hasMarginBottom={q?.options?.length - 1 !== qoIndex}
                  selected={foundAnswered?.value === qo.id}
                >
                  <Radio value={qo?.id} name="radio" disabled={disabled} />
                  <StyledText
                    variant="body3"
                    setColor={"neutral40"}
                    style={{ fontWeight: 600 }}
                  >
                    {qo?.value}
                  </StyledText>
                </RadioContainer>
              );
            })}

            {typeof q.justification === "boolean" && q.justification ? (
              <>
                <RadioContainer
                  hasMarginTop={true}
                  selected={hasJustificationAnswered}
                >
                  <Radio
                    value={"justification"}
                    name="radio"
                    disabled={disabled}
                  />
                  <StyledText
                    variant="body3"
                    setColor={"neutral40"}
                    style={{ fontWeight: 600 }}
                  >
                    Outro
                  </StyledText>
                </RadioContainer>

                {hasJustificationAnswered ? (
                  <RadioContainer hasMarginTop={true} selected={false}>
                    <Radio
                      value={"justification"}
                      name="radio"
                      style={{ visibility: "hidden" }}
                      disabled={disabled}
                    />
                    <TextField
                      label={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          Sua resposta
                          {q.required ? (
                            <ErrorAsterisk>*</ErrorAsterisk>
                          ) : (
                            <></>
                          )}
                        </div>
                      }
                      fullWidth
                      value={
                        hasJustificationAnswered
                          ? foundAnswered?.justification
                          : ""
                      }
                      disabled={disabled}
                      onChange={(e: any) => {
                        onChange({
                          value: "",
                          justification: e.target.value,
                        });
                      }}
                    />
                  </RadioContainer>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </RadioGroup>
        </>
      ) : q.answerFormat === "select-box" ? (
        <>
          {q.options.map((qo, qoIndex) => {
            return (
              <CheckboxContainer
                key={qoIndex}
                hasMarginBottom={q?.options?.length - 1 !== qoIndex}
                selected={foundAnswered?.value?.includes(qo.id)}
              >
                <Checkbox
                  checked={foundAnswered?.value?.includes(qo.id) || false}
                  disabled={disabled}
                  onChange={(e) => {
                    let values = foundAnswered?.value || [];

                    if (e.target.checked) {
                      values = concat(values, qo.id);
                    } else {
                      values = values.filter((v) => v !== qo.id);
                    }

                    onChange({ value: values });
                  }}
                />
                <StyledText
                  variant="body3"
                  setColor={"neutral40"}
                  style={{ fontWeight: 600 }}
                >
                  {qo?.value}
                </StyledText>
              </CheckboxContainer>
            );
          })}

          {typeof q.justification === "boolean" && q.justification ? (
            <>
              <CheckboxContainer
                hasMarginTop={true}
                selected={hasJustificationAnswered}
              >
                <Checkbox
                  checked={hasJustificationAnswered}
                  disabled={disabled}
                  onChange={(e) => {
                    const values = foundAnswered?.value || [];

                    onChange({
                      value: values,
                      justification: e.target.checked ? "" : true,
                    });
                  }}
                />
                <StyledText
                  variant="body3"
                  setColor={"neutral40"}
                  style={{ fontWeight: 600 }}
                >
                  Outro
                </StyledText>
              </CheckboxContainer>

              {hasJustificationAnswered ? (
                <CheckboxContainer
                  hasMarginTop={true}
                  selected={false}
                  isHidden={true}
                >
                  <Checkbox
                    value={hasJustificationAnswered}
                    disabled={disabled}
                  />
                  <TextField
                    label={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        Sua resposta
                        {q.required ? <ErrorAsterisk>*</ErrorAsterisk> : <></>}
                      </div>
                    }
                    fullWidth
                    disabled={disabled}
                    value={
                      hasJustificationAnswered
                        ? foundAnswered?.justification
                        : ""
                    }
                    onChange={(e: any) => {
                      const values = foundAnswered?.value || [];

                      onChange({
                        value: values,
                        justification: e.target.value,
                      });
                    }}
                  />
                </CheckboxContainer>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </>
      ) : q.answerFormat === "dropdown" ? (
        <StyledSelectField
          value={foundAnswered?.value || ""}
          label={q.title}
          disabled={disabled}
          options={q.options.map((qo) => {
            return { label: qo?.value, value: qo?.id };
          })}
          onSelectChange={(_: any, option) => {
            onChange({ value: option.value });
          }}
        />
      ) : ["scale", "emoji", "star", "heart", "number", "enps"]?.includes(
          q.answerFormat
        ) ? (
        <Scale
          scaleRange={q?.scaleRange}
          lowerLabel={q?.lowerLabel}
          upperLabel={q?.upperLabel}
          scale={q?.answerFormat}
          type={type}
          initialValue={foundAnswered?.value}
          onChange={(value) =>
            onChange({
              value: value !== undefined ? value : "",
              hasToDelete: value === undefined ? true : false,
            })
          }
          disabled={disabled}
          isMobile={isMobile}
        />
      ) : (
        <></>
      )}
    </>
  );
};
